import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import "./GuidePage.css" // Optional CSS for styling
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import GuideService from "../../services/GuideService.js"

const OurGuide = () => {
  const [guides, setGuides] = useState([]) // Guides state
  const [loading, setLoading] = useState(true) // Loading state
  const [selectedGuide, setSelectedGuide] = useState(null) // Selected guide for modal
  const [modalOpen, setModalOpen] = useState(false) // Modal state

  // Fetch guides from GuideService
  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const data = await GuideService.getAllGuides()
        setGuides(data)
      } catch (error) {
        console.error("Error fetching guides:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchGuides()
  }, [])

  // Open modal with selected guide details
  const handleReadMore = guide => {
    setSelectedGuide(guide)
    setModalOpen(true)
  }

  // Toggle modal visibility
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <h1 className="text-center mb-4">Guides & Resources</h1>
        <p className="text-center text-muted mb-5">
          Explore our expert guides and resources to help you navigate workforce
          management with confidence.
        </p>

        {loading ? (
          <p className="text-center">Loading guides...</p>
        ) : guides.length === 0 ? (
          <p className="text-center text-muted">
            No guides available at the moment.
          </p>
        ) : (
          <Row>
            {guides.map(guide => (
              <Col md="6" lg="4" className="mb-4" key={guide.key}>
                <Card className="h-100 shadow border-0">
                  <CardBody>
                    <CardTitle tag="h5">{guide.title}</CardTitle>
                    <CardText>
                      {guide.description.length > 100
                        ? `${guide.description.substring(0, 100)}...`
                        : guide.description}
                    </CardText>
                    <Button
                      color="primary"
                      onClick={() => handleReadMore(guide)}
                    >
                      Read More
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>

      {/* Modal for guide details */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{selectedGuide?.title}</ModalHeader>
        <ModalBody>
          <p>{selectedGuide?.description}</p>
        </ModalBody>
      </Modal>

      <Footer />
    </>
  )
}

export default OurGuide
