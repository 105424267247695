import React, { useState, useEffect } from "react"
import Board from "react-trello"
import { Modal, Button } from "react-bootstrap"
import JobDataService from "services/JobDataService"
import AllocationModal from "./AllocationModal.js"
import TimeModal from "./TimeModal.js"
import CancelJobModal from "./CancelJobModal.js"
import "./master.css"
import OrganisationDataService from "services/OrganisationDataService"

const MasterKanban = () => {
  const [jobs, setJobs] = useState([])
  const [currentJob, setCurrentJob] = useState(null)
  const [showJobModal, setShowJobModal] = useState(false)
  const [isAllocationModalOpen, setIsAllocationModalOpen] = useState(false)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [isCancelJobModalOpen, setIsCancelJobModalOpen] = useState(false)

  // Function to fetch organisation names and update jobs with correct provider name
  const fetchOrganisationNames = async jobsData => {
    const updatedJobs = await Promise.all(
      jobsData.map(async job => {
        if (job.provider) {
          const businessName =
            await OrganisationDataService.getOrganisationBusinessName(
              job.provider
            )
          return { ...job, providerName: businessName }
        }
        return job
      })
    )

    // Sort jobs by createdAt (timestamp in milliseconds)
    updatedJobs.sort((a, b) => b.createdAt - a.createdAt) // Sort in descending order by timestamp

    setJobs(updatedJobs)
  }
  
  useEffect(() => {
    const unsubscribe = JobDataService.getAll(async data => {
      await fetchOrganisationNames(data)
    })
    return () => unsubscribe && unsubscribe()
  }, [])

  const handleDragEnd = async (cardId, sourceLaneId, targetLaneId) => {
    const updatedJob = jobs.find(job => job.jobID === cardId)
    if (updatedJob) {
      updatedJob.jobstatus = targetLaneId
      await JobDataService.updateJob(cardId, updatedJob)
      setJobs([...jobs])
    }
  }

  const openAllocationModal = job => {
    setCurrentJob(job)
    setIsAllocationModalOpen(true)
  }

  // Data structure for Kanban board
  const data = {
    lanes: [
      {
        id: "open",
        title: "Open",
        cards: jobs
          .filter(job => job.jobstatus === "open")
          .map(job => ({
            id: job.jobID,
            title: job.jobTitle + " | " + (job.employee || ""),
            description: `Provider: ${
              job.providerName || "Unknown"
            }, Location: ${job.location}`,
          })),
      },
      {
        id: "allocated",
        title: "Allocated",
        cards: jobs
          .filter(job => job.jobstatus === "allocated")
          .map(job => ({
            id: job.jobID,
            title: job.jobTitle + " | " + (job.employee || ""),
            description: `Provider: ${
              job.providerName || "Unknown"
            }, Location: ${job.location}`,
          })),
      },
      {
        id: "completed",
        title: "Completed",
        cards: jobs
          .filter(job => job.jobstatus === "completed")
          .map(job => ({
            id: job.jobID,
            title: job.jobTitle + " | " + (job.employee || ""),
            description: `Provider: ${
              job.providerName || "Unknown"
            }, Location: ${job.location}`,
          })),
      },
      {
        id: "cancelled",
        title: "Cancelled",
        cards: jobs
          .filter(job => job.jobstatus === "cancelled")
          .map(job => ({
            id: job.jobID,
            title: job.jobTitle + " | " + (job.employee || ""),
            description: `Provider: ${
              job.providerName || "Unknown"
            }, Location: ${job.location}`,
          })),
      },
    ],
  }

  const handleCardClick = cardId => {
    const job = jobs.find(j => j.jobID === cardId)
    setCurrentJob(job)
    setShowJobModal(true)
  }

  const closeJobModal = () => {
    setShowJobModal(false)
  }

  return (
    <div className="page-content">
      <h1 style={{ float: "right" }}>
        <a href="master" className="">
          Scheduler View
        </a>{" "}
        |{" "}
        <a href="masterg" className="">
          Gantt View
        </a>
      </h1>
      <Board
        data={data}
        draggable
        laneDraggable={false}
        handleDragEnd={handleDragEnd}
        onCardClick={handleCardClick}
      />

      <Modal show={showJobModal} onHide={closeJobModal}>
        <Modal.Header closeButton>
          <Modal.Title>Job Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentJob && (
            <div>
              <p>
                <strong>Job ID:</strong> {currentJob.jobID}
              </p>
              <p>
                <strong>Title:</strong> {currentJob.jobTitle}
              </p>
              <p>
                <strong>Provider:</strong>{" "}
                {currentJob.providerName || "Unknown"}
              </p>
              <p>
                <strong>Location:</strong> {currentJob.location}
              </p>
              <p>
                <strong>Start Date:</strong> {currentJob.startDate}
              </p>
              <p>
                <strong>End Date:</strong> {currentJob.endDate}
              </p>
              <p>
                <strong>Status:</strong> {currentJob.jobstatus}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeJobModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => openAllocationModal(currentJob)}
          >
            Allocate Worker
          </Button>
        </Modal.Footer>
      </Modal>

      <AllocationModal
        visible={isAllocationModalOpen}
        onHiding={() => setIsAllocationModalOpen(false)}
        jobData={currentJob}
      />

      <TimeModal
        visible={isTimeModalOpen}
        onHiding={() => setIsTimeModalOpen(false)}
        jobData={currentJob}
      />

      <CancelJobModal
        visible={isCancelJobModalOpen}
        onHiding={() => setIsCancelJobModalOpen(false)}
        jobData={currentJob}
      />
    </div>
  )
}

export default MasterKanban
