import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import CareerCenterService from "../../services/CareerCenterService.js"
import JobApplicationDataService from "../../services/JobApplicationDataService.js"
import heroImage from "../../assets/images/career.jpeg"
import "./Careers.css"

const Careers = () => {
  const [jobListings, setJobListings] = useState([]) // Job listings from Firestore
  const [modalOpen, setModalOpen] = useState(false) // Modal state
  const [selectedJob, setSelectedJob] = useState(null) // Selected job
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  })
  const [resumeFile, setResumeFile] = useState(null) // Resume file state

  // Fetch job listings on component mount
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobs = await CareerCenterService.getAllJobListings()
        setJobListings(jobs)
      } catch (error) {
        console.error("Error fetching job listings:", error)
      }
    }

    fetchJobs()
  }, [])

  // Open modal for selected job
  const handleApplyNow = job => {
    setSelectedJob(job)
    setModalOpen(true)
  }

  // Close modal
  const toggleModal = () => {
    setModalOpen(!modalOpen)
    setFormData({ name: "", email: "", phone: "" })
    setResumeFile(null)
  }

  // Handle form input changes
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Handle resume file change
  const handleFileChange = e => {
    setResumeFile(e.target.files[0])
  }

  // Submit application
  const handleSubmit = async () => {
    if (!resumeFile) {
      alert("Please upload your resume.")
      return
    }

    try {
      const resumeURL = await JobApplicationDataService.uploadResume(
        resumeFile,
        formData.name
      )

      const application = {
        ...formData,
        resumeURL,
        jobTitle: selectedJob.title,
        appliedAt: Date.now(),
      }

      await JobApplicationDataService.createApplication(application)
      alert("Your application has been submitted successfully!")
      toggleModal()
    } catch (error) {
      console.error("Error submitting application:", error)
      alert("An error occurred: " + error.message)
    }
  }

  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <h1 className="display-4">Join Our Team</h1>
            <p className="lead">
              Build your career with Care-Tip and make a difference in
              transforming workforce management.
            </p>
          </Container>
        </section>

        {/* Job Listings Section */}
        <section className="job-listings-section my-5">
          <Container>
            <h2 className="text-center mb-4">Current Openings</h2>
            <Row>
              {jobListings.map(job => (
                <Col md="4" className="mb-4" key={job.id}>
                  <Card className="h-100 shadow border-0">
                    <CardBody>
                      <CardTitle tag="h5">{job.title}</CardTitle>
                      <CardText>
                        <strong>Location:</strong> {job.location} <br />
                        <strong>Type:</strong> {job.type}
                      </CardText>
                      <CardText>{job.description}</CardText>
                      <Button
                        color="primary"
                        onClick={() => handleApplyNow(job)}
                      >
                        Apply Now
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        {/* Application Modal */}
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Apply for {selectedJob?.title}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <Input
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                />
              </FormGroup>
              <FormGroup>
                <Label for="resume">Resume</Label>
                <Input
                  id="resume"
                  name="resume"
                  type="file"
                  onChange={handleFileChange}
                  required
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>
              Submit Application
            </Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <Footer />
    </>
  )
}

export default Careers
