import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const API_URL =
  process.env.NODE_ENV === "production"
    ? "/api/send-email" // Vercel API route
    : "http://localhost:3000/api/send-email"; 

const EmailForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  })
  
  const [loading, setLoading] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const validateForm = () => {
    if (!formData.email || !formData.subject || !formData.message) {
      toast.error("All fields are required!")
      return false
    }
    return true
  }

const handleSubmit = async e => {
  e.preventDefault()
  if (!validateForm()) return
  setLoading(true)
  try {
    await axios.post(API_URL, formData) // Ensure this is sending a POST request
    toast.success("Email sent successfully!")
  } catch (error) {
    console.error("Error:", error.response?.data || error.message)
    toast.error(error.response?.data?.error || "Failed to send email")
  } finally {
    setLoading(false)
  }
}


  return (
    <div style={{ padding: "20px", fontFamily: "Arial" }}>
      <h1>Send Email</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{ margin: "10px", padding: "5px", width: "300px" }}
          />
        </div>
        <div>
          <label>Subject:</label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            style={{ margin: "10px", padding: "5px", width: "300px" }}
          />
        </div>
        <div>
          <label>Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            style={{
              margin: "10px",
              padding: "5px",
              width: "300px",
              height: "100px",
            }}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: "10px 20px",
            cursor: loading ? "not-allowed" : "pointer",
          }}
        >
          {loading ? "Sending..." : "Send Email"}
        </button>
      </form>
    </div>
  )
}

export default EmailForm
