import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import SubscriptionService from "../../services/SubscriptionService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const SubscriptionManager = () => {
  const [subscriptions, setSubscriptions] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  })

  // Fetch all subscriptions
  const fetchSubscriptions = async () => {
    try {
      const data = await SubscriptionService.getAllSubscriptions()
      setSubscriptions(data)
    } catch (error) {
      console.error("Error fetching subscriptions:", error)
    }
  }

  useEffect(() => {
    fetchSubscriptions()
  }, [])

  // Open modal for adding or editing
  const handleEdit = subscription => {
    setCurrentSubscription(subscription)
    setFormData(subscription)
    setModalOpen(true)
  }

  const handleAdd = () => {
    setCurrentSubscription(null)
    setFormData({ name: "", email: "" })
    setModalOpen(true)
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  // Handle form input changes
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Save subscription (Create or Update)
  const handleSave = async () => {
    try {
      if (currentSubscription) {
        // Update existing subscription
        await SubscriptionService.updateSubscription(
          currentSubscription.id,
          formData
        )
        alert("Subscription updated successfully!")
      } else {
        // Create new subscription
        await SubscriptionService.createSubscription(formData)
        alert("Subscription added successfully!")
      }
      fetchSubscriptions()
      toggleModal()
    } catch (error) {
      console.error("Error saving subscription:", error)
      alert("An error occurred while saving the subscription.")
    }
  }

  // Delete subscription
  const handleDelete = async id => {
    if (!window.confirm("Are you sure you want to delete this subscription?")) {
      return
    }
    try {
      await SubscriptionService.deleteSubscription(id)
      alert("Subscription deleted successfully!")
      fetchSubscriptions()
    } catch (error) {
      console.error("Error deleting subscription:", error)
      alert("An error occurred while deleting the subscription.")
    }
  }

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1>Subscription Manager</h1>
        <Button color="success" className="mb-3" onClick={handleAdd}>
          Add New Subscription
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map(subscription => (
              <tr key={subscription.id}>
                <td>{subscription.name}</td>
                <td>{subscription.email}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEdit(subscription)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(subscription.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal for Adding/Editing Subscriptions */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentSubscription ? "Edit Subscription" : "Add New Subscription"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter subscriber's name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter subscriber's email"
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Footer />
    </>
  )
}

export default SubscriptionManager
