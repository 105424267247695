import React, { useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError } from "../../store/actions.js"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link, useNavigate } from "react-router-dom"

// import images
import profile from "assets/images/profile-img.png"

import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/caretip.jpg"
import { getFirebaseBackend } from "../../helpers/firebase_helper.js"

const Register = props => {
  //meta title
  document.title = "Register | Care-Tip -  Admin & Dashboard"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
      given_name: "",
      family_name: "",
      user_type: "",
      user_company: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your Email"),
      username: Yup.string().required("Please enter your Username"),
      password: Yup.string().required("Please enter your Password"),
      given_name: Yup.string().required("Please enter your Given Name"),
      family_name: Yup.string().required("Please enter your Family Name"),
      user_type: Yup.string().required("Please enter your User Type"),
      user_company: Yup.string().required("Please enter your Company"),
    }),
    onSubmit: values => {
      const { email, password, given_name, family_name, user_type, user_company } = values
      const firebaseBackend = getFirebaseBackend();
      // Create the user with email and password
      firebaseBackend
        .registerUser(email, password)
        .then(registeredUser => {
          // After successful registration, add additional details to Firestore
          const userDetails = {
            email,
            firstName: given_name,
            lastName: family_name,
            userType: user_type,
            userCompany: user_company,
            // other necessary fields
          }
          firebaseBackend.addNewUserToFirestore(registeredUser.uid, userDetails)
        })
        .catch(error => {
          console.error("Registration error", error)
          // Handle registration error
        })
    },
  })

  const selectAccountState = state => state.Account
  const AccountProperties = createSelector(selectAccountState, account => ({
    user: account.user,
    registrationError: account.registrationError,
    success: account.success,
    // loading: account.loading,
  }))

  const {
    user,
    registrationError,
    success,
    // loading
  } = useSelector(AccountProperties)

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 2000)
  }, [success])

  const handleRegisterClick = () => {
    // try {
    //   const firebaseBackend = getFirebaseBackend()
    //   const email = validation.values.email
    //   const password = validation.values.password

    //   firebaseBackend
    //     .registerUser(email, password)
    //     .then(user => {
    //       console.log("Registered user: " + JSON.stringify( user));
    //       firebaseBackend.addNewUserToFirestore(user)
    //       console.log("User registered successfully", user)

    //       // Additional logic after successful registration
    //     })
    //     .catch(error => {
    //       console.error("Registration error", error)
    //       // Handle registration error
    //     })
    // } catch (error) {
    //   console.log(error.message)
    // }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Get your Care-Tip account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="70"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {user && success ? (
                        <Alert color="success">
                          User has been successfully registered
                        </Alert>
                      ) : (
                        <>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              type="text"
                              placeholder="Enter username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          {/* New fields for given_name, family_name, user_type, and user_company */}
                          <div className="mb-3">
                            <Label className="form-label">Given Name</Label>
                            <Input
                              name="given_name"
                              className="form-control"
                              placeholder="Enter given name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.given_name || ""}
                              invalid={
                                validation.touched.given_name &&
                                validation.errors.given_name
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Family Name</Label>
                            <Input
                              name="family_name"
                              className="form-control"
                              placeholder="Enter family name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.family_name || ""}
                              invalid={
                                validation.touched.family_name &&
                                validation.errors.family_name
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">User Type</Label>
                            <Input
                              name="user_type"
                              className="form-control"
                              placeholder="Enter user type"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_type || ""}
                              invalid={
                                validation.touched.user_type &&
                                validation.errors.user_type
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Company</Label>
                            <Input
                              name="user_company"
                              className="form-control"
                              placeholder="Enter company"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_company || ""}
                              invalid={
                                validation.touched.user_company &&
                                validation.errors.user_company
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                              onClick={handleRegisterClick}
                            >
                              Register
                            </button>
                          </div>
                        </>
                      )}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Care-Tip{" "}
                          <a
                            href="/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary"
                          >
                            Terms of Use
                          </a>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Care-Tip. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Precious Tech.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
