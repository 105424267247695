import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import NewsService from "../../services/NewsService.js"
import "./Press.css"

const Press = () => {
  const [news, setNews] = useState([]) // News state
  const [loading, setLoading] = useState(true) // Loading state
  const [selectedNews, setSelectedNews] = useState(null) // Selected news for modal
  const [modalOpen, setModalOpen] = useState(false) // Modal state

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await NewsService.getAllNews()
        setNews(data)
      } catch (error) {
        console.error("Error fetching news:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchNews()
  }, [])

  // Open modal with selected news details
  const handleReadMore = newsItem => {
    setSelectedNews(newsItem)
    setModalOpen(true)
  }

  // Toggle modal visibility
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      <Header />

      <div className="page-content">
        <section
          className="hero-section text-white text-center py-5"
          style={{
            backgroundImage: `url(https://www.care-tip.com/static/media/press.7c26d9f0235bb46d0f70.jpeg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Container>
            <h1 className="display-4">Press & Media</h1>
            <p className="lead">
              Explore the latest news, updates, and press releases from
              Care-Tip.
            </p>
          </Container>
        </section>

        {/* Latest News Section */}
        <section className="latest-news-section my-5">
          <Container>
            <h2 className="text-center mb-4">Latest News & Press Releases</h2>

            {loading ? (
              <p className="text-center">Loading news...</p>
            ) : news.length === 0 ? (
              <p className="text-center text-muted">
                No news available at the moment.
              </p>
            ) : (
              <Row>
                {news.map(item => (
                  <Col md="4" className="mb-4" key={item.key}>
                    <Card className="h-100 shadow border-0">
                      <img
                        src={item.image}
                        alt={item.title}
                        className="card-img-top"
                      />
                      <CardBody>
                        <CardTitle tag="h5">{item.title}</CardTitle>
                        <p className="text-muted">{item.date}</p>
                        <p>{item.excerpt}</p>
                        <Button
                          color="primary"
                          onClick={() => handleReadMore(item)}
                        >
                          Read More
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </section>
      </div>

      {/* Modal for news details */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{selectedNews?.title}</ModalHeader>
        <ModalBody>
          <img
            src={selectedNews?.image}
            alt={selectedNews?.title}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          <p>{selectedNews?.date}</p>
          <p>{selectedNews?.excerpt}</p>
        </ModalBody>
      </Modal>

      <Footer />
    </>
  )
}

export default Press
