import React, { useState, useEffect } from "react"
import {
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import BlogService from "../../services/BlogService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const BlogManager = () => {
  const [blogs, setBlogs] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentBlog, setCurrentBlog] = useState(null)
  const [formData, setFormData] = useState({
    title: "",
    excerpt: "",
    image: "",
  })

  const fetchBlogs = async () => {
    try {
      const data = await BlogService.getAllBlogs()
      setBlogs(data)
    } catch (error) {
      console.error("Error fetching blogs:", error)
    }
  }

  useEffect(() => {
    fetchBlogs()
  }, [])

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleAdd = () => {
    setCurrentBlog(null)
    setFormData({ title: "", excerpt: "", image: "" })
    setModalOpen(true)
  }

  const handleEdit = blog => {
    setCurrentBlog(blog)
    setFormData({ title: blog.title, excerpt: blog.excerpt, image: blog.image })
    setModalOpen(true)
  }

  const handleSave = async () => {
    try {
      if (currentBlog) {
        await BlogService.updateBlog(currentBlog.key, formData)
        alert("Blog updated successfully!")
      } else {
        const newBlog = { ...formData, createdAt: Date.now() }
        await BlogService.createBlog(newBlog)
        alert("New blog added successfully!")
      }
      fetchBlogs()
      toggleModal()
    } catch (error) {
      console.error("Error saving blog:", error)
      alert("An error occurred while saving the blog.")
    }
  }

  const handleDelete = async blogKey => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      try {
        await BlogService.deleteBlog(blogKey)
        alert("Blog deleted successfully!")
        fetchBlogs()
      } catch (error) {
        console.error("Error deleting blog:", error)
        alert("An error occurred while deleting the blog.")
      }
    }
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <h1>Blog Manager</h1>
        <Button color="success" className="mb-3" onClick={handleAdd}>
          Add New Blog
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Title</th>
              <th>Excerpt</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map(blog => (
              <tr key={blog.key}>
                <td>{blog.title}</td>
                <td>{blog.excerpt}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEdit(blog)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(blog.key)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentBlog ? "Edit Blog" : "Add New Blog"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                id="title"
                name="title"
                value={formData.title}
                onChange={e =>
                  setFormData({ ...formData, title: e.target.value })
                }
                placeholder="Enter blog title"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="excerpt">Excerpt</Label>
              <Input
                id="excerpt"
                name="excerpt"
                type="textarea"
                value={formData.excerpt}
                onChange={e =>
                  setFormData({ ...formData, excerpt: e.target.value })
                }
                placeholder="Enter blog excerpt"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="image">Image URL</Label>
              <Input
                id="image"
                name="image"
                value={formData.image}
                onChange={e =>
                  setFormData({ ...formData, image: e.target.value })
                }
                placeholder="Enter blog image URL"
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Footer />
    </>
  )
}

export default BlogManager
