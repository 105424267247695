import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./OurTeam.css" // Include custom CSS for flip effect
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const teamMembers = [
  {
    name: "John Doe",
    position: "CEO & Founder",
    imageFront: "https://via.placeholder.com/200", // Replace with actual image
    imageBack: "https://via.placeholder.com/200/FF0000", // Replace with actual image
    description: "Passionate about creating solutions that matter.",
  },
  {
    name: "Jane Smith",
    position: "Chief Technology Officer",
    imageFront: "https://via.placeholder.com/200", // Replace with actual image
    imageBack: "https://via.placeholder.com/200/00FF00", // Replace with actual image
    description: "Driving innovation with cutting-edge technology.",
  },
  {
    name: "Emily Davis",
    position: "Head of Marketing",
    imageFront: "https://via.placeholder.com/200", // Replace with actual image
    imageBack: "https://via.placeholder.com/200/0000FF", // Replace with actual image
    description: "Building connections and growing our brand.",
  },
  {
    name: "Michael Brown",
    position: "Lead Developer",
    imageFront: "https://via.placeholder.com/200", // Replace with actual image
    imageBack: "https://via.placeholder.com/200/FFFF00", // Replace with actual image
    description: "Crafting seamless and efficient software solutions.",
  },
]

const OurTeam = () => {
  return (
    <>
      <Header />

      <Container className="my-5">
        <h1 className="text-center mb-4">Meet Our Team</h1>
        <Row>
          {teamMembers.map((member, index) => (
            <Col md="3" sm="6" className="mb-4" key={index}>
              <div className="team-card">
                <div className="team-card-inner">
                  {/* Front of the card */}
                  <div className="team-card-front">
                    <img
                      src={member.imageFront}
                      alt={member.name}
                      className="img-fluid"
                    />
                    <h5 className="mt-3">{member.name}</h5>
                    <p>{member.position}</p>
                  </div>
                  {/* Back of the card */}
                  <div className="team-card-back">
                    <p>{member.description}</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default OurTeam
