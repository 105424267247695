import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import BlogService from "../../services/BlogService.js"
import "./BlogPage.css"

const OurBlog = () => {
  const [blogs, setBlogs] = useState([]) // Blogs state
  const [loading, setLoading] = useState(true) // Loading state
  const [selectedBlog, setSelectedBlog] = useState(null) // Selected blog for modal
  const [modalOpen, setModalOpen] = useState(false) // Modal state

  // Fetch blogs from BlogService
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const data = await BlogService.getAllBlogs()
        setBlogs(data)
      } catch (error) {
        console.error("Error fetching blogs:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchBlogs()
  }, [])

  // Open modal with selected blog details
  const handleReadMore = blog => {
    setSelectedBlog(blog)
    setModalOpen(true)
  }

  // Toggle modal visibility
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <h1 className="text-center mb-4">Our Blog</h1>
        <p className="text-center text-muted mb-5" style={{ fontSize: "16px" }}>
          Stay updated with the latest trends, insights, and best practices in
          workforce management.
        </p>

        {loading ? (
          <p className="text-center">Loading blogs...</p>
        ) : blogs.length === 0 ? (
          <p className="text-center text-muted">
            No blogs available at the moment.
          </p>
        ) : (
          <Row>
            {blogs.map(blog => (
              <Col md="6" lg="4" className="mb-4" key={blog.key}>
                <Card className="h-100 shadow border-0">
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="card-img-top"
                    style={{ objectFit: "cover", height: "200px" }}
                  />
                  <CardBody>
                    <CardTitle tag="h5">{blog.title}</CardTitle>
                    <CardText>
                      {blog.excerpt.length > 100
                        ? `${blog.excerpt.substring(0, 100)}...`
                        : blog.excerpt}
                    </CardText>
                    <Button
                      color="primary"
                      onClick={() => handleReadMore(blog)}
                    >
                      Read More
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>

      {/* Modal for blog details */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{selectedBlog?.title}</ModalHeader>
        <ModalBody>
          <img
            src={selectedBlog?.image}
            alt={selectedBlog?.title}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          <p>{selectedBlog?.excerpt}</p>
        </ModalBody>
      </Modal>

      <Footer />
    </>
  )
}

export default OurBlog
