import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
} from "firebase/database"

class DemoScheduleService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/demoSchedules") // Reference to the "demoSchedules" collection
  }

  /**
   * Create a new demo schedule
   * @param {Object} schedule - The demo schedule data
   * @returns {Promise} - Promise resolving with the new record key
   */
  createSchedule(schedule) {
    return push(this.dbRef, schedule) // Add new schedule to Firebase Realtime Database
  }

  /**
   * Retrieve all demo schedules
   * @returns {Promise} - Promise resolving with an array of schedules
   */
  getAllSchedules() {
    return new Promise((resolve, reject) => {
      onValue(
        this.dbRef,
        snapshot => {
          const data = snapshot.val()
          const schedules = data
            ? Object.keys(data).map(key => ({ id: key, ...data[key] }))
            : []
          resolve(schedules)
        },
        error => reject(error)
      )
    })
  }

  /**
   * Update an existing demo schedule
   * @param {string} scheduleId - The ID of the schedule to update
   * @param {Object} updatedData - The updated schedule data
   * @returns {Promise} - Promise resolving with the result of the update
   */
  updateSchedule(scheduleId, updatedData) {
    const scheduleRef = ref(getDatabase(), `/demoSchedules/${scheduleId}`)
    return update(scheduleRef, updatedData) // Update the specific schedule
  }

  /**
   * Delete a demo schedule
   * @param {string} scheduleId - The ID of the schedule to delete
   * @returns {Promise} - Promise resolving with the result of the deletion
   */
  deleteSchedule(scheduleId) {
    const scheduleRef = ref(getDatabase(), `/demoSchedules/${scheduleId}`)
    return remove(scheduleRef) // Remove the specific schedule
  }
}

export default new DemoScheduleService()
