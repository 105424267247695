import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const ROICalculator = () => {
  const [agencySpend, setAgencySpend] = useState(45000)
  const [agencyHours, setAgencyHours] = useState(3000)
  const [numberOfSites, setNumberOfSites] = useState(25)
  const [permanentStaff, setPermanentStaff] = useState(750)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")

  const calculateSavings = () => {
    // Example formula for savings
    const efficiencyGain = 0.15 // Assumes a 15% efficiency gain
    const timeSavings = agencyHours * efficiencyGain
    const costSavings = agencySpend * efficiencyGain
    return {
      timeSavings: timeSavings.toFixed(0),
      costSavings: costSavings.toFixed(2),
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    alert("Thank you! Your report request has been submitted.")
  }

  const { timeSavings, costSavings } = calculateSavings()

  return (
    <>
      <Header />
      <Container className="my-5">
        <Row className="justify-content-center">
          <Col md="8">
            <h1 className="text-center mb-4">
              Temporary Staffing ROI Calculator
            </h1>
            <p className="text-center">
              How much time and money could you save using the Care-Tip
              Supplier Management Platform?
            </p>

            {/* ROI Calculator Section */}
            <Form>
              <FormGroup>
                <Label for="agencySpend">
                  What is your agency spend per month?
                </Label>
                <Input
                  id="agencySpend"
                  type="number"
                  value={agencySpend}
                  onChange={e => setAgencySpend(Number(e.target.value))}
                  placeholder="Enter monthly agency spend"
                />
              </FormGroup>

              <FormGroup>
                <Label for="agencyHours">Agency hours per month?</Label>
                <Input
                  id="agencyHours"
                  type="number"
                  value={agencyHours}
                  onChange={e => setAgencyHours(Number(e.target.value))}
                  placeholder="Enter monthly agency hours"
                />
              </FormGroup>

              <FormGroup>
                <Label for="numberOfSites">Number of sites?</Label>
                <Input
                  id="numberOfSites"
                  type="number"
                  value={numberOfSites}
                  onChange={e => setNumberOfSites(Number(e.target.value))}
                  placeholder="Enter number of sites"
                />
              </FormGroup>

              <FormGroup>
                <Label for="permanentStaff">Number of staff?</Label>
                <Input
                  id="permanentStaff"
                  type="number"
                  value={permanentStaff}
                  onChange={e => setPermanentStaff(Number(e.target.value))}
                  placeholder="Enter number of permanent staff"
                />
              </FormGroup>
            </Form>

            {/* Display Savings */}
            <div className="my-4 text-center">
              <h3>Your Potential Savings:</h3>
              <p>
                <strong>Time Savings:</strong> {timeSavings} hours/month
                <br />
                <strong>Cost Savings:</strong> £{costSavings}/month
              </p>
            </div>

            {/* Request Full Report Section */}
            <h4 className="text-center mb-3">Request the Full Report</h4>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  placeholder="Enter your first name"
                />
              </FormGroup>

              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder="Enter your last name"
                />
              </FormGroup>

              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
              </FormGroup>

              <Button color="primary" type="submit" className="w-100">
                Submit
              </Button>
            </Form>

            {/* Schedule a Demo Link */}
            <div className="text-center mt-4">
              <Link to="/book" className="btn btn-link">
                Schedule a demo today
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default ROICalculator
