import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
} from "firebase/database"
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"

class JobApplicationDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/jobApplications") // Reference to job applications
    this.storage = getStorage() // Firebase Storage instance
  }

  // Create a new application in Realtime Database
  createApplication(application) {
    return push(this.dbRef, application)
  }

  // Upload resume to Firebase Storage and return the download URL
  async uploadResume(file, applicantName) {
    const fileRef = storageRef(
      this.storage,
      `resumes/${applicantName}_${Date.now()}`
    )
    await uploadBytes(fileRef, file) // Upload the file
    return getDownloadURL(fileRef) // Get the file's public URL
  }

  // Retrieve all applications from Realtime Database
  getAllApplications() {
    return new Promise((resolve, reject) => {
      onValue(
        this.dbRef,
        snapshot => {
          const data = snapshot.val()
          const applications = data
            ? Object.keys(data).map(key => ({ id: key, ...data[key] }))
            : []
          resolve(applications)
        },
        error => reject(error)
      )
    })
  }

  // Update an application in Realtime Database
  updateApplication(applicationId, updatedData) {
    const applicationRef = ref(
      getDatabase(),
      `/jobApplications/${applicationId}`
    )
    return update(applicationRef, updatedData)
  }

  // Delete an application from Realtime Database
  deleteApplication(applicationId) {
    const applicationRef = ref(
      getDatabase(),
      `/jobApplications/${applicationId}`
    )
    return remove(applicationRef)
  }
}

export default new JobApplicationDataService()
