import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import DemoScheduleService from "../../services/DemoScheduleService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const BookADemo = () => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [timeSlot, setTimeSlot] = useState("9:30 AM")
  const [meetingDuration, setMeetingDuration] = useState("30 minutes")
  const [modalOpen, setModalOpen] = useState(false)

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    products: "",
    captcha: "",
  })

  const timeSlots = [
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
  ]

  const meetingDurations = ["15 minutes", "30 minutes", "45 minutes", "1 hour"]

  // Disable weekends in the calendar
  const isWeekday = date => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  // Handle form field changes
  const handleFormChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Handle step 1: Validate date and open modal for personal details
  const handleDateSelection = e => {
    e.preventDefault()

    if (!selectedDate) {
      alert("Please select a date.")
      return
    }

    // Open modal for user details
    setModalOpen(true)
  }

  // Handle final submission of demo details
  const handleFinalSubmit = async e => {
    e.preventDefault()

    if (!formData.captcha || formData.captcha !== "12") {
      alert("Invalid captcha. Please try again.")
      return
    }

    // Prepare data to submit
    const demoDetails = {
      date: selectedDate.toISOString(),
      timeSlot,
      meetingDuration,
      ...formData,
    }

    try {
      await DemoScheduleService.createSchedule(demoDetails)
      alert(
        "Your demo has been successfully scheduled. We will contact you soon."
      )
      setModalOpen(false)

      // Reset all fields
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        products: "",
        captcha: "",
      })
      setSelectedDate(null)
      setTimeSlot("9:30 AM")
      setMeetingDuration("30 minutes")
    } catch (error) {
      console.error("Error scheduling demo:", error)
      alert("An error occurred while scheduling your demo. Please try again.")
    }
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <Row className="justify-content-center">
          <Col md="6">
            <h1 className="text-center mb-4">Book a Demo</h1>
            <Form onSubmit={handleDateSelection}>
              {/* Date Picker */}
              <FormGroup>
                <Label for="date">Select a Date</Label>
                <DatePicker
                  id="date"
                  selected={selectedDate}
                  onChange={date => setSelectedDate(date)}
                  filterDate={isWeekday}
                  placeholderText="Choose a date (Monday to Friday)"
                  className="form-control"
                />
              </FormGroup>

              {/* Meeting Duration */}
              <FormGroup>
                <Label for="duration">Meeting Duration</Label>
                <Input
                  id="duration"
                  type="select"
                  value={meetingDuration}
                  onChange={e => setMeetingDuration(e.target.value)}
                >
                  {meetingDurations.map(duration => (
                    <option key={duration} value={duration}>
                      {duration}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              {/* Time Slots */}
              <FormGroup>
                <Label for="timeSlot">Select a Time</Label>
                <Input
                  id="timeSlot"
                  type="select"
                  value={timeSlot}
                  onChange={e => setTimeSlot(e.target.value)}
                >
                  {timeSlots.map(time => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              {/* Submit Button */}
              <Button color="primary" type="submit" className="w-100">
                Next: Add Personal Details
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* Modal for Personal Details */}
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        size="lg"
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Enter Your Details
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFinalSubmit}>
            {/* Personal Details */}
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
                placeholder="Enter your first name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
                placeholder="Enter your last name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleFormChange}
                placeholder="Enter your email"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
                placeholder="Enter your phone number"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="company">Company</Label>
              <Input
                id="company"
                name="company"
                value={formData.company}
                onChange={handleFormChange}
                placeholder="Enter your company name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="products">Interested Products</Label>
              <Input
                id="products"
                name="products"
                value={formData.products}
                onChange={handleFormChange}
                placeholder="Enter products you're interested in"
              />
            </FormGroup>
            <FormGroup>
              <Label>Captcha: What is 6 + 6?</Label>
              <Input
                name="captcha"
                value={formData.captcha}
                onChange={handleFormChange}
                placeholder="Enter the answer"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input type="checkbox" required /> I agree to the data privacy
              statement.
            </FormGroup>

            <Button color="primary" type="submit" className="w-100">
              Submit Demo Request
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <Footer />
    </>
  )
}

export default BookADemo
