import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
} from "firebase/database"

class NewsService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/news") // Reference to the 'news' path in the Realtime Database
  }

  // Fetch all news
  getAllNews() {
    return new Promise((resolve, reject) => {
      const newsByLatestRef = query(this.dbRef, orderByChild("createdAt"))

      onValue(
        newsByLatestRef,
        snapshot => {
          const data = snapshot.val()
          let news = []

          if (data) {
            // Convert object to an array of news items
            news = Object.keys(data).map(key => ({ key, ...data[key] }))
            // Sort news by 'createdAt' in descending order
            news.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
          }

          resolve(news) // Resolve with sorted news
        },
        error => {
          reject(error) // Handle any errors
        }
      )
    })
  }

  // Create a new news item
  createNews(newsItem) {
    return push(this.dbRef, newsItem)
  }

  // Update an existing news item
  updateNews(key, updatedNews) {
    const specificNewsRef = ref(getDatabase(), `/news/${key}`)
    return update(specificNewsRef, updatedNews)
  }

  // Delete a news item
  deleteNews(key) {
    const specificNewsRef = ref(getDatabase(), `/news/${key}`)
    return remove(specificNewsRef)
  }
}

export default new NewsService()
