import React, { useState, useEffect } from "react"
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import DemoScheduleService from "../../services/DemoScheduleService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const DemoScheduleManager = () => {
  const [schedules, setSchedules] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState(null)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    employees: "",
    products: "",
    date: "",
    timeSlot: "",
    meetingDuration: "",
  })

  // Fetch all schedules
  const fetchSchedules = async () => {
    try {
      const data = await DemoScheduleService.getAllSchedules()
      setSchedules(data)
    } catch (error) {
      console.error("Error fetching schedules:", error)
    }
  }

  useEffect(() => {
    fetchSchedules()
  }, [])

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Open modal for adding or editing
  const handleEdit = schedule => {
    setCurrentSchedule(schedule)
    setFormData(schedule)
    setModalOpen(true)
  }

  const handleAdd = () => {
    setCurrentSchedule(null)
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      employees: "",
      products: "",
      date: "",
      timeSlot: "",
      meetingDuration: "",
    })
    setModalOpen(true)
  }

  // Close modal
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  // Save schedule (Create or Update)
  const handleSave = async () => {
    try {
      if (currentSchedule) {
        // Update schedule
        await DemoScheduleService.updateSchedule(currentSchedule.id, formData)
        alert("Schedule updated successfully!")
      } else {
        // Create new schedule
        await DemoScheduleService.createSchedule(formData)
        alert("New schedule added successfully!")
      }
      fetchSchedules()
      toggleModal()
    } catch (error) {
      console.error("Error saving schedule:", error)
      alert("An error occurred while saving the schedule.")
    }
  }

  // Delete schedule
  const handleDelete = async id => {
    try {
      await DemoScheduleService.deleteSchedule(id)
      alert("Schedule deleted successfully!")
      fetchSchedules()
    } catch (error) {
      console.error("Error deleting schedule:", error)
      alert("An error occurred while deleting the schedule.")
    }
  }

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1>Demo Schedule Manager</h1>
        <Button color="success" className="mb-3" onClick={handleAdd}>
          Add New Schedule
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Company</th>
              <th>Date</th>
              <th>Time Slot</th>
              <th>Duration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {schedules.map(schedule => (
              <tr key={schedule.id}>
                <td>
                  {schedule.firstName} {schedule.lastName}
                </td>
                <td>{schedule.email}</td>
                <td>{schedule.phone}</td>
                <td>{schedule.company}</td>
                <td>{new Date(schedule.date).toLocaleDateString()}</td>
                <td>{schedule.timeSlot}</td>
                <td>{schedule.meetingDuration}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEdit(schedule)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(schedule.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal for Adding/Editing Schedule */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentSchedule ? "Edit Schedule" : "Add New Schedule"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="company">Company</Label>
              <Input
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="employees">Employees</Label>
              <Input
                id="employees"
                name="employees"
                value={formData.employees}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="products">Products</Label>
              <Input
                id="products"
                name="products"
                value={formData.products}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="date">Date</Label>
              <Input
                id="date"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="timeSlot">Time Slot</Label>
              <Input
                id="timeSlot"
                name="timeSlot"
                value={formData.timeSlot}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="meetingDuration">Meeting Duration</Label>
              <Input
                id="meetingDuration"
                name="meetingDuration"
                value={formData.meetingDuration}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Footer />
    </>
  )
}

export default DemoScheduleManager
