import React, { useEffect, useState } from "react"
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore"
import { functions } from "../../firebase.js" // Ensure the correct path to firebase.js
import {
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import ContactDataService from "../../services/ContactDataService.js"; // Adjust path as needed
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const ContactManagement = () => {
  const [contacts, setContacts] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentContact, setCurrentContact] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

 // const contactsCollection = collection(functions, "contacts")

  // Fetch contacts from Firestore
const fetchContacts = async () => {
  try {
    const data = await ContactDataService.getAll()
    setContacts(data)
  } catch (error) {
    console.error("Error fetching contacts: ", error)
  }
}

useEffect(() => {
  fetchContacts()
}, [])

const handleUpdate = async (key, updatedContact) => {
  try {
    await ContactDataService.updateContact(key, updatedContact)
    fetchContacts() // Refresh the list after update
  } catch (error) {
    console.error("Error updating contact: ", error)
  }
}

const handleDelete = async key => {
  try {
    await ContactDataService.deleteContact(key)
    fetchContacts() // Refresh the list after deletion
  } catch (error) {
    console.error("Error deleting contact: ", error)
  }
}

  // Handle form input changes
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Add or update contact
  const handleSave = async () => {
    if (currentContact) {
      // Update contact
      const docRef = doc(functions, "contacts", currentContact.id)
      await updateDoc(docRef, formData)
    } else {
      // Add new contact
      await addDoc(contactsCollection, formData)
    }
    setModalOpen(false)
    setFormData({ name: "", email: "", phone: "", message: "" })
    setCurrentContact(null)
    fetchContacts()
  }

  // Open modal for editing
  const handleEdit = contact => {
    setFormData(contact)
    setCurrentContact(contact)
    setModalOpen(true)
  }

  return (
    <>
       <Header />
    <div className="page-content">
      <Container>
        <h1 className="my-4">Contact Management</h1>
        <Button color="primary" onClick={() => setModalOpen(true)}>
          Add Contact
        </Button>
        <Table striped className="mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Message</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map(contact => (
              <tr key={contact.id}>
                <td>{contact.name}</td>
                <td>{contact.email}</td>
                <td>{contact.phone}</td>
                <td>{contact.message}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    onClick={() => handleEdit(contact)}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(contact.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Modal for Add/Edit */}
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
            {currentContact ? "Edit Contact" : "Add Contact"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <Input
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="message">Message</Label>
                <Input
                  id="message"
                  name="message"
                  type="textarea"
                  value={formData.message}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div
    >
    <Footer/>
    </>
  )
}

export default ContactManagement
