import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
} from "firebase/database"

class ContactDataService {
  constructor() {
    // Reference to the 'contacts' path in Firebase Realtime Database
    this.dbRef = ref(getDatabase(), "/contacts")
  }

  // Fetch all contacts
  getAll() {
    return new Promise((resolve, reject) => {
      // Query to order contacts by 'createdAt' field
      const contactsByLatestRef = query(this.dbRef, orderByChild("createdAt"))

      onValue(
        contactsByLatestRef,
        snapshot => {
          const data = snapshot.val()
          let contacts = []

          if (data) {
            // Convert object to an array of contacts and include the 'key' property
            contacts = Object.keys(data).map(key => ({ key, ...data[key] }))

            // Sort contacts by 'createdAt' in descending order (latest first)
            contacts.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)) // Descending order
          }

          resolve(contacts) // Resolve with sorted contacts
        },
        error => {
          reject(error) // Reject promise if there is an error
        }
      )
    })
  }

  // Create a new contact
  createContact(contact) {
    return push(this.dbRef, contact)
  }

  // Update an existing contact by key
  updateContact(key, value) {
    const specificContactRef = ref(getDatabase(), `/contacts/${key}`)
    return update(specificContactRef, value)
  }

  // Delete a contact by key
  deleteContact(key) {
    const specificContactRef = ref(getDatabase(), `/contacts/${key}`)
    return remove(specificContactRef)
  }
}

export default new ContactDataService()
