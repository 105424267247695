import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
} from "firebase/database"

class BlogService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/blogs") // Reference to the 'blogs' path in the Realtime Database
  }

  // Fetch all blogs
  getAllBlogs() {
    return new Promise((resolve, reject) => {
      const blogsByLatestRef = query(this.dbRef, orderByChild("createdAt"))

      onValue(
        blogsByLatestRef,
        snapshot => {
          const data = snapshot.val()
          let blogs = []

          if (data) {
            // Convert object to an array of blogs
            blogs = Object.keys(data).map(key => ({ key, ...data[key] }))
            // Sort blogs by 'createdAt' in descending order
            blogs.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
          }

          resolve(blogs) // Resolve with sorted blogs
        },
        error => {
          reject(error) // Handle any errors
        }
      )
    })
  }

  // Create a new blog
  createBlog(blog) {
    return push(this.dbRef, blog)
  }

  // Update an existing blog
  updateBlog(key, updatedBlog) {
    const specificBlogRef = ref(getDatabase(), `/blogs/${key}`)
    return update(specificBlogRef, updatedBlog)
  }

  // Delete a blog
  deleteBlog(key) {
    const specificBlogRef = ref(getDatabase(), `/blogs/${key}`)
    return remove(specificBlogRef)
  }
}

export default new BlogService()
