import React, { useState, useRef, useEffect, useCallback } from "react"
import "./invoicepage.css"
import { Label, FormGroup, Input } from "reactstrap"
import FinanceDataService from "./../../services/FinanceDataService.js"
import OrganisationDataService from "services/OrganisationDataService" // Service for fetching providers
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import { v4 as uuidv4 } from "uuid"

const InvoicePage = () => {
  const invoiceRef = useRef(null)

  const [invoiceHeader, setInvoiceHeader] = useState({
    invoiceID: "",
    fromOrg: "",
    fromOrgAddress: "",
    fromOrgEmail: "",
    toOrg: "",
    toOrgAddress: "",
    toOrgEmail: "",
    toOrgVAT: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    terms: "",
  })

  const [lineItems, setLineItems] = useState([])
  const [vatRate, setVatRate] = useState(0.2) // Example VAT rate of 20%
  const [showModal, setShowModal] = useState(false)
  const [invoiceData, setInvoiceData] = useState({})
  const [selectedFile, setSelectedFile] = useState(null)

  const [providers, setProviders] = useState([]) // State to hold provider list
  const [selectedProvider, setSelectedProvider] = useState("") // State to hold selected provider

  useEffect(() => {
    // Fetch the list of providers (organisations)
    OrganisationDataService.getAll(providers => {
      setProviders(providers)
    })
  }, [])

  useEffect(() => {
    // Fetch all invoices when component mounts
    FinanceDataService.getAll(fetchedInvoices => {
      if (fetchedInvoices.length > 0) {
        setInvoiceData(fetchedInvoices[0])
      }
    })
  }, [])

  const handleShowInvoice = () => {
    setShowModal(true)
  }

  const generateJobID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `INV-${year}-${shortUuid}`.toUpperCase()
  }

  const handleHeaderChange = e => {
    setInvoiceHeader({ ...invoiceHeader, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    setInvoiceHeader({ invoiceID: generateJobID() })
  }, [])


const handleAddLineItem = () => {
  setLineItems([
    ...lineItems,
    {
      id: uuidv4(), // Ensure each new line item has a unique and stable ID
      agencyName: "",
      activity: "",
      description: "",
      hours: 0,
      nightRate: 0,
      amount: 0,
    },
  ])
}

const calculateAmounts = () => {
  setLineItems(prevItems => {
    return prevItems.map(item => {
      const hours = parseFloat(item.hours) || 0
      const nightRate = parseFloat(item.nightRate) || 0
      const amount = hours * nightRate
      return { ...item, amount }
    })
  })
}



const handleLineItemChange = (index, e) => {
  const { name, value } = e.target

  // Update only the value in state, no calculations yet
  setLineItems(prevItems => {
    return prevItems.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value }
      }
      return item
    })
  })
}





  const handleRemoveLineItem = index => {
    const updatedItems = lineItems.filter((_, i) => i !== index)
    setLineItems(updatedItems)
  }

  const handleProviderChange = e => {
    setSelectedProvider(e.target.value)
  }

  const downloadPdfDocument = () => {
    html2canvas(invoiceRef.current)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        })
        const imgWidth = 210
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
        pdf.save("download.pdf")
      })
      .catch(error => {
        console.error("Error in html2canvas or jsPDF: ", error)
      })
  }

  const handleFileChange = e => {
    setSelectedFile(e.target.files[0])
  }

  const validateForm = () => {
    if (invoiceHeader) {
      // Ensure fields are not undefined and apply trim safely
      if (!invoiceHeader.fromOrgAddress?.trim()) {
        alert("From Organization Address is required.")
        return false
      }
      if (!invoiceHeader.fromOrgEmail?.trim()) {
        alert("From Organization Email is required.")
        return false
      }
      if (!invoiceHeader.toOrgAddress?.trim()) {
        alert("To Organization Address is required.")
        return false
      }
      if (!invoiceHeader.toOrgEmail?.trim()) {
        alert("To Organization Email is required.")
        return false
      }
      if (!invoiceHeader.invoiceNumber?.trim()) {
        alert("Invoice Number is required.")
        return false
      }
      if (!invoiceHeader.invoiceDate?.trim()) {
        alert("Invoice Date is required.")
        return false
      }
      if (!invoiceHeader.dueDate?.trim()) {
        alert("Due Date is required.")
        return false
      }
    }

    // Ensure there's at least one line item
    if (lineItems.length === 0) {
      alert("At least one line item is required.")
      return false
    }

    return true // All checks pass
  }

  // Calculate total before submission
  const calculateTotal = () => {
    return lineItems.reduce((total, item) => {
      const hours = parseFloat(item.hours) || 0
      const rate = parseFloat(item.nightRate) || 0
      return total + hours * rate
    }, 0)
  }

  const calculateVAT = total => {
    return total * vatRate
  }

  const calculateGrandTotal = (total, vat) => {
    return total + vat
  }

  // Function to validate a line item
  const validateLineItem = item => {
    if (!item.hours || isNaN(item.hours) || item.hours <= 0) {
      return false
    }
    if (!item.nightRate || isNaN(item.nightRate) || item.nightRate <= 0) {
      return false
    }
    return true
  }

  const handleSubmit = async () => {
      calculateAmounts()

    if (!validateForm()) {
      return
    }

    // Validate each line item
    for (let item of lineItems) {
      if (!validateLineItem(item)) {
        alert("Invalid line item data.")
        return
      }
    }

    const invoiceData = {
      invoiceHeader: {
        ...invoiceHeader,
        provider: selectedProvider, // Include selected provider in submission
      },
      lineItems,
      total: calculateTotal(),
      vat: calculateVAT(calculateTotal()),
      grandTotal: calculateGrandTotal(
        calculateTotal(),
        calculateVAT(calculateTotal())
      ),
    }

    try {
      const financeRefKey = await FinanceDataService.createFinance(invoiceData)

      if (selectedFile) {
        await FinanceDataService.uploadFile(financeRefKey, selectedFile)
      }

      alert("Invoice submitted successfully!")
      window.location.href = "/invoices";

    } catch (error) {
      alert("Error submitting invoice: " + error.message)
    }
  }

  // LineItem Component Definition
const LineItem = ({ item, onChange, index, onRemove }) => {
  return (
    <div className="line-item">
      <span>{index + 1}.</span>
      <input
        type="text"
        name="agencyName"
        placeholder="Agency Name"
        value={item.agencyName}
        onChange={e => onChange(index, e)}
      />
      <input
        type="text"
        name="activity"
        placeholder="Activity"
        value={item.activity}
        onChange={e => onChange(index, e)}
      />
      <input
        type="text"
        name="description"
        placeholder="Description"
        value={item.description}
        onChange={e => onChange(index, e)}
      />
      <input
        type="number"
        name="hours"
        placeholder="Hours"
        value={item.hours}
        onChange={e => onChange(index, e)}
      />
      <input
        type="number"
        name="nightRate"
        placeholder="Night Rate"
        value={item.nightRate}
        onChange={e => onChange(index, e)}
      />
      <input
        type="number"
        name="amount"
        placeholder="Amount"
        value={item.amount}
        disabled={true} // Amount is calculated
        onChange={e => onChange(index, e)}
      />
      <button className="removebtn" onClick={() => onRemove(index)}>
        Remove
      </button>
    </div>
  )
}


  return (
    <div className="page-content">
      <h2>Create Invoice</h2>
      <label>
        Attach File:
        <input type="file" onChange={handleFileChange} />
      </label>

      {/* Provider selection dropdown */}
      <FormGroup>
        <Label for="provider">Select a Provider:</Label>
        <Input
          type="select"
          name="provider"
          id="provider"
          value={selectedProvider}
          onChange={handleProviderChange}
        >
          <option value="">Select a Provider</option>
          {providers.map(provider => (
            <option key={provider.key} value={provider.key}>
              {provider.businessName}
            </option>
          ))}
        </Input>
      </FormGroup>

      <form>
        <div className="form-group">
          <div className="xinput-group">
            <label>FROM:</label>
            <label>
              Organisation:
              <Input
                type="text"
                name="fromOrg"
                value={invoiceHeader.fromOrg}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Address:
              <Input
                type="text"
                name="fromOrgAddress"
                value={invoiceHeader.fromOrgAddress}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Email:
              <Input
                type="email"
                name="fromOrgEmail"
                value={invoiceHeader.fromOrgEmail}
                onChange={handleHeaderChange}
              />
            </label>
          </div>
          <div className="xinput-group">
            <label>TO:</label>
            <label>
              Organisation:
              <Input
                type="text"
                name="toOrg"
                value={invoiceHeader.toOrg}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Address:
              <Input
                type="text"
                name="toOrgAddress"
                value={invoiceHeader.toOrgAddress}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Email:
              <Input
                type="email"
                name="toOrgEmail"
                value={invoiceHeader.toOrgEmail}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              VAT:
              <Input
                type="text"
                name="toOrgVAT"
                value={invoiceHeader.toOrgVAT}
                onChange={handleHeaderChange}
              />
            </label>
          </div>
        </div>

        <div className="input-group">
          <label>
            Invoice Number:
            <Input
              type="text"
              name="invoiceNumber"
              value={invoiceHeader.invoiceNumber}
              onChange={handleHeaderChange}
            />
          </label>
          <label>
            Date of Invoice:
            <Input
              type="date"
              name="invoiceDate"
              value={invoiceHeader.invoiceDate}
              onChange={handleHeaderChange}
            />
          </label>
          <label>
            Due Date of Invoice:
            <Input
              type="date"
              name="dueDate"
              value={invoiceHeader.dueDate}
              onChange={handleHeaderChange}
            />
          </label>
          <label>
            <div>Terms of Receipt:</div>
            <textarea
              name="terms"
              style={{ width: "100%" }}
              value={invoiceHeader.terms}
              onChange={handleHeaderChange}
            />
          </label>
        </div>
      </form>

      <div>
        <h2 style={{ color: "blue" }}>Invoice Items</h2>
        {lineItems.map((item, index) => (
          <LineItem
            key={item.id || index}
            item={item}
            index={index}
            onChange={handleLineItemChange}
            onRemove={handleRemoveLineItem}
          />
        ))}
        <button onClick={handleAddLineItem}>Add Line Item</button>
        {/* Add a calculate button */}
        <button onClick={calculateAmounts}>Calculate Total</button>{" "}
      </div>

      <button onClick={handleSubmit}>Submit Invoice</button>
    </div>
  )
}

export default InvoicePage
