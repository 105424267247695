import React from "react";
import { Navigate } from "react-router-dom";

// Pages Component
import Chat from "../pages/Chat/Chat.js";

// Profile
import UserProfile from "../pages/Authentication/user-profile.js";

// Pages Calendar
import Calendar from "../pages/Calendar/index.js";

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list.js";
import InvoiceDetail from "../pages/Invoices/invoices-detail.js";
import InvoicePage from "../pages/Invoices/InvoicePage.js";
import InvoiceManager from "../pages/Invoices/InvoiceManager.js"
import InvoiceClient from "../pages/Invoices/InvoiceClient.js"

// Authentication related pages
import Login from "../pages/Authentication/Login.js";
import Logout from "../pages/Authentication/Logout.js";
import Register from "../pages/Authentication/Register.js";
import AdminRegister from "../pages/Authentication/AdminRegister.js"
import ClientRegister from "../pages/Authentication/ClientRegister.js"
import ForgetPwd from "../pages/Authentication/ForgetPassword.js";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login.js";
import Login2 from "../pages/AuthenticationInner/Login2.js";
import Register1 from "../pages/AuthenticationInner/Register.js";
import Register2 from "../pages/AuthenticationInner/Register2.js";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw.js";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2.js";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword.js";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2.js";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen.js";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2.js";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail.js";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2.js";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification.js";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2.js";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification.js";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2.js";

// Dashboard
import Dashboard from "../pages/Dashboard/index.js";

import TopJobsChart from "../pages/Dashboard/TopJobsChart.js"


import CompletedJobsByTimesheetStatus from "../pages/Allocate/CompletedJobsByTimesheetStatus.js";

//Organisation
import organisation from "../pages/Organisation/index.js";


//BusinessUnit
import businessunit from "../pages/BusinessUnit/index.js"

import workers from "../pages/Workers/index.js";
import Users from "../pages/Authentication/Users.js"


import CreateEmailTemplateForm from "../pages/Email/CreateEmailTemplateForm.js"
// Charts
import ChartApex from "../pages/Charts/Apexcharts.js";
import ChartjsChart from "../pages/Charts/ChartjsChart.js";
import EChart from "../pages/Charts/EChart.js";
import SparklineChart from "../pages/Charts/SparklineChart.js";
import ChartsKnob from "../pages/Charts/charts-knob.js";
import ReCharts from "../pages/Charts/ReCharts.js";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle.js";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons.js";
import IconDripicons from "../pages/Icons/IconDripicons.js";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign.js";
import IconFontawesome from "../pages/Icons/IconFontawesome.js";

//Tables
import BasicTables from "../pages/Tables/BasicTables.js";
import DatatableTables from "../pages/Tables/DatatableTables.js";


// Forms
import FormElements from "../pages/Forms/FormElements.js";
import FormLayouts from "../pages/Forms/FormLayouts.js";
import FormAdvanced from "../pages/Forms/FormAdvanced.js";
import FormEditors from "../pages/Forms/FormEditors.js";
import FormValidations from "../pages/Forms/FormValidations.js";
import FormMask from "../pages/Forms/FormMask.js";
import FormRepeater from "../pages/Forms/FormRepeater.js";
import FormUpload from "../pages/Forms/FormUpload.js";
import FormWizard from "../pages/Forms/FormWizard.js";

//Ui
// import UiAlert from "../pages/Ui/UiAlert";
// import UiButtons from "../pages/Ui/UiButtons";
// import UiCards from "../pages/Ui/UiCards";
// import UiCarousel from "../pages/Ui/UiCarousel";
// import UiColors from "../pages/Ui/UiColors";
// import UiDropdown from "../pages/Ui/UiDropdown";
// import UiGeneral from "../pages/Ui/UiGeneral";
// import UiGrid from "../pages/Ui/UiGrid";
// import UiImages from "../pages/Ui/UiImages";
// import UiLightbox from "../pages/Ui/UiLightbox";
// import UiModal from "../pages/Ui/UiModal";
// import UiProgressbar from "../pages/Ui/UiProgressbar";
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
// import UiTypography from "../pages/Ui/UiTypography";
// import UiVideo from "../pages/Ui/UiVideo";
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
// import UiRating from "../pages/Ui/UiRating";
// import UiRangeSlider from "../pages/Ui/UiRangeSlider";
// import UiNotifications from "../pages/Ui/ui-notifications";
// import UiOffCanvas from "pages/Ui/UiOffCanvas";
// import UiUtilitie from "../pages/Ui/UiUtilitie";
// import UiPlaceholders from "../pages/Ui/UiPlaceholders";
// import UiToasts from "../pages/Ui/UiToast";

//Pages
import PagesStarter from "../pages/Utility/pages-starter.js";
import PagesMaintenance from "../pages/Utility/pages-maintenance.js";
import PagesComingsoon from "../pages/Utility/pages-comingsoon.js";
import PagesTimeline from "../pages/Utility/pages-timeline.js";
import PagesFaqs from "../pages/Utility/pages-faqs.js";
import PagesPricing from "../pages/Utility/pages-pricing.js";
import Pages404 from "../pages/Utility/pages-404.js";
import Pages500 from "../pages/Utility/pages-500.js";
import Confirm from "../pages/Utility/confirm.js";

import Support from "../pages/Support/Support.js";

import PrivacyPolicy from "../pages/Privacy/PrivacyPolicy.js";
import PrivacyChoices from "../pages/Privacy/PrivacyChoices.js";
import UnsubscribePage from "../pages/Unsubscribe/UnsubscribePage.js";

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid.js";
import ContactsList from "../pages/Contacts/ContactList/contacts-list.js";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile.js";

import OrganisationManager from "pages/Organisation/OrganisationManager";
import BusinessUnitManager from "pages/BusinessUnit/BusinessUnitManager";
import WorkerManager from "pages/Workers/WorkerManager";
import JobManager from "pages/Jobs/JobManager";
import Booking from "pages/Jobs/Booking"
import ActivityManager from "pages/Activity/ActivityManager";
import JobBoard from "pages/Jobs/JobBoard";
import JobClient from "pages/Jobs/JobClient";
import BookingClient from "pages/Jobs/BookingClient"

import JobAgent from "pages/Jobs/JobAgent";
import JobWorker from "pages/Jobs/JobWorker";
import AgreementManager from "pages/Agreements/AgreementManager";
import AgreementAgent from "pages/Agreements/AgreementAgent";
import FinanceManager from "pages/Finance/FinanceManager";
import FinanceAgent from "pages/Finance/FinanceAgent";
import TimesheetForm from "pages/Timesheets/TimesheetForm";
import TimesheetManager from "pages/Timesheets/TimesheetManager";
import TimesheetClient from "pages/Allocate/ApproveClientTimesheets"
import TimesheetList from "pages/Timesheets/TimesheetList"
import TimesheetWorker from "pages/Timesheets/TimesheetWorker"
import ApplicationManager from "pages/ApplicationDetail/ApplicationManager";
import ApplicationWorker from "pages/ApplicationDetail/ApplicationWorker"
import WorkerClient from "pages/Workers/WorkerClient";
import Worker from "pages/Workers/Worker";
import AgreementClient from "pages/Agreements/AgreementClient";
import OrganisationClient from "pages/Organisation/OrganisationClient";
import OrganisationAgent from "pages/Organisation/OrganisationAgent";
import WorkerAgent from "pages/Workers/WorkerAgent";
import TimesheetAgent from "pages/Timesheets/TimesheetAgent";
import Schedule from "pages/Timesheets/Schedule";
import ScheduleWorker from "pages/Timesheets/ScheduleWorker";
import Master from "pages/Allocate/Master";
import MasterClient from "pages/Allocate/MasterClient"
import MasterKanban from "pages/Allocate/MasterKanban";
import MasterGantt from "pages/Allocate/MasterGantt";
import MasterWorker from "pages/Allocate/MasterWorker"
import MasterMap from "pages/Allocate/MasterMap"


import ApproveTimesheets from "pages/Allocate/ApproveTimesheets";
import ApproveClientTimesheets from "pages/Allocate/ApproveClientTimesheets"
import ApproveWorkerTimesheets from "pages/Allocate/ApproveWorkerTimesheets"

import Schedul from "pages/Timesheets/Schedul";
import Allocate from "pages/Timesheets/Allocate"

import InvoiceForm from "pages/Invoices/InvoiceForm"
import { components } from "react-trello";
import Email from "pages/Email/email"
import EmailForm from "pages/Email/EmailForm"

import Home from "pages/External/Home";
import AboutUs from "pages/External/AboutUs";
import ROICalculator from "pages/External/ROICalculator"
import BookADemo from "pages/External/BookADemo"
import OurTeam from "pages/External/OurTeam"
import OurStory from "pages/External/OurStory"
import OurBlog from "pages/External/OurBlog"
import OurGuide from "pages/External/OurGuide"
import StaffingService from "pages/External/StaffingService"
import RotaManagement from "pages/External/RotaManagement"
import DigitalTimesheet from "pages/External/DigitalTimesheet"
import ContactUs from "pages/External/ContactUs"
import Recruitment from "pages/External/Recruitment"
import WagesSalaries from "pages/External/WagesSalaries"
import InvoicesPg from "pages/External/InvoicesPg"

import Careers from "pages/External/Careers"
import Press from "pages/External/Press"
import ContactManagement from "pages/Contacts/ContactManagement"
import CareerManager from "pages/Career/CareerManager"
import PressKit from "pages/External/PressKit"
import DemoScheduleManager from "pages/DemoSchedules/DemoScheduleManager"
import SubscriptionManager from "pages/Subscription/SubscriptionManager"
import CareerCenterManager from "pages/Career/CareerCenterManager"
import GuideManager from "pages/Guide/GuideManager"
import BlogManager from "pages/Blog/BlogManager"
import NewsManager from "pages/News/NewsManager";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/tjchart", component: <TopJobsChart /> },

  { path: "/organisation", component: <OrganisationManager /> },
  { path: "/businessunit", component: <BusinessUnitManager /> },
  { path: "/workers", component: <WorkerManager /> },
  { path: "/worker", component: <Worker /> },
  { path: "/workerclient", component: <WorkerClient /> },
  { path: "/workeragent", component: <WorkerAgent /> },
  { path: "/jobs", component: <JobManager /> },
  { path: "/jobboard", component: <JobBoard /> },

  { path: "/jobclient", component: <JobClient /> },
  { path: "/bookingclient", component: <BookingClient /> },
  { path: "/jobworker", component: <JobWorker /> },
  { path: "/jobagent", component: <JobAgent /> },
  { path: "/agreements", component: <AgreementManager /> },
  { path: "/finance", component: <FinanceManager /> },
  { path: "/financeagent", component: <FinanceAgent /> },
  { path: "/timesheets", component: <TimesheetManager /> },
  { path: "/timesheetclient", component: <TimesheetClient /> },
  { path: "/timesheetagent", component: <TimesheetAgent /> },
  { path: "/timesheetform", component: <TimesheetForm /> },
  { path: "/timesheetlist", component: <TimesheetList /> },
  { path: "/timesheetworker", component: <TimesheetWorker /> },
  { path: "/applicationdetail", component: <ApplicationManager /> },
  { path: "/applicationworker", component: <ApplicationWorker /> },
  { path: "/agreementclient", component: <AgreementClient /> },
  { path: "/agreementagent", component: <AgreementAgent /> },
  { path: "/organisationclient", component: <OrganisationClient /> },
  { path: "/organisationagent", component: <OrganisationAgent /> },
  { path: "/users", component: <Users /> },
  { path: "/ctl", component: <CreateEmailTemplateForm /> },
  { path: "/schedule", component: <Schedule /> },
  { path: "/scheduleworker", component: <ScheduleWorker /> },
  { path: "/schedul", component: <Schedul /> },
  { path: "/master", component: <Master /> },
  { path: "/masterclient", component: <MasterClient /> },
  { path: "/masterworker", component: <MasterWorker /> },
  { path: "/masterk", component: <MasterKanban /> },
  { path: "/masterg", component: <MasterGantt /> },
  { path: "/masterw", component: <MasterWorker /> },
  { path: "/masterm", component: <MasterMap /> },

  {
    path: "/mytime",
    component: <CompletedJobsByTimesheetStatus />,
  },
  {
    path: "/approve",
    component: <ApproveTimesheets />,
  },

  {
    path: "/approveclient",
    component: <ApproveClientTimesheets />,
  },

  {
    path: "/approveworker",
    component: <ApproveWorkerTimesheets />,
  },

  //chat
  { path: "/chat", component: <Chat /> },

  { path: "/chat", component: <Chat /> },
  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/usermanager", component: <UserProfile /> },
  { path: "/adminregister", component: <AdminRegister /> },
  { path: "/clientregister", component: <ClientRegister /> },
  { path: "/activity", component: <ActivityManager /> },

  //Email
  // { path: "/email-read", component: <EmailRead /> },
  // { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  // { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  // { path: "/email-template-billing", component: <EmailTemplateBilling /> },
  { path: "/email", component: <Email /> },
  { path: "/emailform", component: <EmailForm /> },

  //Invoices
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/invoices-detail/:id", component: <InvoiceDetail /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },
  { path: "/invoicepage", component: <InvoicePage /> },
  { path: "/invoices", component: <InvoiceManager /> },
  { path: "/invoiceclient", component: <InvoiceClient /> },
  { path: "/InvoiceForm", component: <InvoiceForm /> },

  //job
  // { path: "/job-grid", component: <JobGrid /> },
  // { path: "/joblisting", component: <JobListing /> },
  { path: "/booking", component: <Booking /> },

  // { path: "/job-details", component: <JobDetails /> },
  // { path: "/job-details/:jobID", component: <JobDetails /> },

  // { path: "/job-categories", component: <JobCategories /> },
  // { path: "/job-list", component: <JobList /> },
  // { path: "/job-apply", component: <ApplyJobs /> },
  // { path: "/candidate-overview", component: <CandidateOverview /> },
  { path: "/allocate", component: <Allocate /> },

  // Contacts
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/re-charts", component: <ReCharts /> },

  // Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },

  // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-layouts", component: <FormLayouts /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-repeater", component: <FormRepeater /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },

  // // Ui
  // { path: "/ui-alerts", component: <UiAlert /> },
  // { path: "/ui-buttons", component: <UiButtons /> },
  // { path: "/ui-cards", component: <UiCards /> },
  // { path: "/ui-carousel", component: <UiCarousel /> },
  // { path: "/ui-colors", component: <UiColors /> },
  // { path: "/ui-dropdowns", component: <UiDropdown /> },
  // { path: "/ui-general", component: <UiGeneral /> },
  // { path: "/ui-grid", component: <UiGrid /> },
  // { path: "/ui-images", component: <UiImages /> },
  // { path: "/ui-lightbox", component: <UiLightbox /> },
  // { path: "/ui-modals", component: <UiModal /> },
  // { path: "/ui-progressbars", component: <UiProgressbar /> },
  // { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  // { path: "/ui-typography", component: <UiTypography /> },
  // { path: "/ui-video", component: <UiVideo /> },
  // { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  // { path: "/ui-rating", component: <UiRating /> },
  // { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  // { path: "/ui-notifications", component: <UiNotifications /> },
  // { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  // { path: "/ui-utilities", component: <UiUtilitie /> },
  // { path: "/ui-placeholders", component: <UiPlaceholders /> },
  // { path: "/ui-toasts", component: <UiToasts /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/confirm", component: <Confirm /> },
  { path: "/privacy", component: <PrivacyPolicy /> },
  { path: "/privacy-choices", component: <PrivacyChoices /> },
  { path: "/unsubscribe", component: <UnsubscribePage /> },
  { path: "/support", component: <Support /> },
  { path: "/home", component: <Home /> },
  { path: "/aboutus", component: <AboutUs /> },
  { path: "/book", component: <BookADemo /> },
  { path: "/calc", component: <ROICalculator /> },
  { path: "/team", component: <OurTeam /> },
  { path: "/story", component: <OurStory /> },
  { path: "/blog", component: <OurBlog /> },
  { path: "/guide", component: <OurGuide /> },
  { path: "/staffing", component: <StaffingService /> },
  { path: "/rota", component: <RotaManagement /> },
  { path: "/digitaltime", component: <DigitalTimesheet /> },
  { path: "/contactus", component: <ContactUs /> },
  { path: "/recruit", component: <Recruitment /> },
  { path: "/wages", component: <WagesSalaries /> },
  { path: "/invoicespg", component: <InvoicesPg /> },
  { path: "/careers", component: <Careers /> },
  { path: "/press", component: <Press /> },
  { path: "/contactmgr", component: <ContactManagement /> },
  { path: "/careermgr", component: <CareerManager /> },
  { path: "/demomgr", component: <DemoScheduleManager /> },
  { path: "/presskit", component: <PressKit /> },
  { path: "/submgr", component: <SubscriptionManager /> },
  { path: "/careercentermgr", component: <CareerCenterManager /> },
  { path: "/guidemgr", component: <GuideManager /> },
  { path: "/blogmgr", component: <BlogManager /> },
  { path: "/newsmgr", component: <NewsManager /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },

  {
    path: "/auth-two-step-verification-2",
    component: <TwostepVerification2 />,
  },
]

export { authProtectedRoutes, publicRoutes };
