import React from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import logoImage from "../../assets/images/caretip.jpg" // Replace with actual paths
import productImage from "../../assets/images/product.png" // Replace with actual paths
import pressReleasePDF from "../../assets/files/PressRelease.pdf" // Replace with actual paths
import "./PressKit.css"

const PressKit = () => {
  const pressKitResources = [
    {
      title: "Company Logo",
      description: "High-resolution versions of our company logo.",
      image: logoImage,
      downloadLink: "/assets/files/company-logo.zip", // Replace with actual paths
    },
    {
      title: "Product Screenshots",
      description: "High-quality screenshots of our product in action.",
      image: productImage,
      downloadLink: "/assets/files/product-screenshots.zip", // Replace with actual paths
    },
    {
      title: "Press Release",
      description: "Latest press release with key updates about our company.",
      image: null,
      downloadLink: pressReleasePDF,
    },
  ]

  const contactInfo = {
    name: "Media Relations Team",
    email: "press@caretip.com",
    phone: "01253834269",
    address: "291-305 Lytham Road FY4 1EW, UK",
  }

  return (
    <>
      <Header />
      <div className="page-content">
        <section
          className="hero-section text-white text-center py-5"
          style={{ backgroundColor: "#333" }}
        >
          <Container>
            <h1 className="display-4">Press Kit</h1>
            <p className="lead">
              Welcome to the Care-Tip Press Kit. Here you'll find everything you
              need to cover our company, products, and latest updates.
            </p>
          </Container>
        </section>

        <section className="resources-section py-5">
          <Container>
            <h2 className="text-center mb-4">Media Resources</h2>
            <Row>
              {pressKitResources.map((resource, index) => (
                <Col md="4" className="mb-4" key={index}>
                  <Card className="shadow border-0">
                    {resource.image && (
                      <img
                        src={resource.image}
                        alt={resource.title}
                        className="card-img-top"
                      />
                    )}
                    <CardBody>
                      <h5 className="card-title">{resource.title}</h5>
                      <p className="card-text">{resource.description}</p>
                      <Button
                        color="primary"
                        href={resource.downloadLink}
                        download
                      >
                        Download
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <section className="contact-section bg-light py-5">
          <Container>
            <h2 className="text-center mb-4">Contact Us</h2>
            <Row className="justify-content-center">
              <Col md="6">
                <Card className="shadow border-0">
                  <CardBody>
                    <h5>Contact Information</h5>
                    <p>
                      <strong>Name:</strong> {contactInfo.name}
                    </p>
                    <p>
                      <strong>Email:</strong>{" "}
                      <a href={`mailto:${contactInfo.email}`}>
                        {contactInfo.email}
                      </a>
                    </p>
                    <p>
                      <strong>Phone:</strong> {contactInfo.phone}
                    </p>
                    <p>
                      <strong>Address:</strong> {contactInfo.address}
                    </p>
                    <Button
                      color="secondary"
                      href={`mailto:${contactInfo.email}`}
                    >
                      Email Us
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default PressKit
