import React, { useEffect, useState, useMemo } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useFormik } from "formik"
import * as Yup from "yup"
import { getFirebaseBackend } from "../../helpers/firebase_helper.js"


import WorkersDataService from "services/WorkersDataService" // Import the service
import OrganisationDataService from "services/OrganisationDataService" // Import the service for organisations
// import EmailService from "services/EmailService" // Import the service for
import { functions } from "../../firebase.js"; // Adjust path if needed
// import EmailService from "../../../EmailService.js/index.js"; //

const AdminRegister = () => {
  document.title = "Register | Care-Tip - Admin & Dashboard"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [alerts, setAlerts] = useState({
    success: false,
    error: false,
    errorMessage: "",
  })

  const [organisations, setOrganisations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState("") // To store selected organisation
  const [userDetails, setUserDetails] = useState({
    companyName: "",
    email: "",
    role: "",
    workerId: "",
  })

  const [info, setInfo] = useState("") // Replaces `this.state.info`

  const [roles, setRoles] = useState({
    worker: false,
  })

  const [workers, setWorkers] = useState([]) // State to hold workers data
  const [selectedWorker, setSelectedWorker] = useState("") // State to hold the selected worker's key

  const { success, registrationError } = useSelector(
    createSelector(
      state => state.Account,
      account => ({
        user: account.user,
        registrationError: account.registrationError,
        success: account.success,
      })
    )
  )

  // Fetch organisations on component mount
  useEffect(() => {
    OrganisationDataService.getAll(data => {
      setOrganisations(data) // Populate organisations dropdown
    })
  }, [])

  // Fetch workers when user type is "Worker"
  useEffect(() => {
    if (roles.worker) {
      WorkersDataService.getAll(data => {
        setWorkers(data)
      })
    }
  }, [roles.worker])

  // Helper function to render input fields
  const renderInputField = (name, type, placeholder, label) => (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[name] || ""}
        invalid={validation.touched[name] && validation.errors[name]}
      />
      {validation.touched[name] && validation.errors[name] && (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      )}
    </div>
  )

  //Handle form validation using Formik and Yup
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: "",
      password: "",
      given_name: "",
      family_name: "",
      user_type: "",
      user_company: "",
      workerId: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter the Email"),
      username: Yup.string().required("Please enter the Username"),
      password: Yup.string().required("Please enter the Password"),
      given_name: Yup.string().required("Please enter the Given Name"),
      family_name: Yup.string().required("Please enter the Family Name"),
      user_type: Yup.string().required("Please enter the User Type"),
    }),
    onSubmit: values => handleRegistration(values),
  })

  const handleRegistration = async values => {
    try {
      const firebaseBackend = getFirebaseBackend()
      const registeredUser = await firebaseBackend.registerUser(
        values.email,
        values.password
      )

      const userDetails = {
        email: values.email,
        firstName: values.given_name,
        lastName: values.family_name,
        userType: values.user_type,
        userCompany: selectedOrg, // Store selected company
        mailReady: roles.worker ? selectedWorker : null,
      }

      // Add user to Firestore
      await firebaseBackend.addNewUserToFirestore(
        registeredUser.uid,
        userDetails
      )


      // Send a welcome email
      // await EmailService.sendEmail({
      //   email: values.email, // Recipient's email address
      //   subject: "Welcome to Care-Tip!",
      //   template: "welcome", // Name of the email template (welcome.hbs)
      //   context: {
      //     name: values.given_name, // Inject dynamic data into the template
      //     company: selectedOrg
      //       ? organisations.find(org => org.key === selectedOrg)?.businessName
      //       : "Care-Tip",
      //   },
      // })


      setInfo(
        `New user ${userDetails.firstName} ${userDetails.lastName} created successfully.`
      )
      setAlerts({ ...alerts, success: true, error: false })
    } catch (error) {
      console.error("Error during registration:", error)
      setAlerts({ ...alerts, error: true, errorMessage: error.message })
    }
  }

  const email = () =>
    {
      alert("Please enter your email address");
  }

   const renderWorkerSelect = () => {
    if (!roles.worker) return null
    return (
      <div className="mb-3">
        <Label className="form-label">Select Worker</Label>
        <Input
          type="select"
          name="worker"
          onChange={e => {
            setSelectedWorker(e.target.value) // Store worker key as workerId
            console.log("Selected worker ID: ", e.target.value) // Log worker key for verification
          }}
        >
          <option value="">Select Worker</option>
          {workers.map(worker => (
            <option key={worker.key} value={worker.key}>
              {worker.firstName} {worker.lastName}
            </option>
          ))}
        </Input>
      </div>
    )
   }

   return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Alert
            color="info"
            isOpen={alerts.success}
            toggle={() => setAlerts({ ...alerts, success: false })}
          >
            User has been successfully registered
          </Alert>
          <Alert
            color="danger"
            isOpen={alerts.error}
            toggle={() => setAlerts({ ...alerts, error: false })}
          >
            Error in registration process: {alerts.errorMessage}
          </Alert>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {/* Other input fields */}
                    {renderInputField("email", "email", "Enter email", "Email")}
                    {renderInputField(
                      "username",
                      "text",
                      "Enter username",
                      "Username"
                    )}
                    {renderInputField(
                      "password",
                      "password",
                      "Enter Password",
                      "Password"
                    )}
                    {renderInputField(
                      "given_name",
                      "text",
                      "Enter given name",
                      "Given Name"
                    )}
                    {renderInputField(
                      "family_name",
                      "text",
                      "Enter family name",
                      "Family Name"
                    )}
                    {/* User Type Selection */}
                    <div className="mb-3">
                      <Label className="form-label">User Type</Label>
                      <Input
                        type="select"
                        name="user_type"
                        onChange={e => {
                          validation.handleChange(e)
                          setRoles({
                            ...roles,
                            worker: e.target.value === "Worker",
                          })
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.user_type}
                        invalid={
                          validation.touched.user_type &&
                          validation.errors.user_type
                        }
                      >
                        <option value="">Select User Type</option>
                        <option value="Super Admin">Super Admin</option>
                        <option value="Client">Client</option>
                        <option value="Client Manager">Client Manager</option>
                        <option value="Agency Super Admin">
                          Agency Super Admin
                        </option>
                        <option value="Worker">Worker</option>
                      </Input>
                    </div>
                    {/* Company Selection */}
                    <div className="mb-3">
                      <Label className="form-label">
                        Company{" "}
                        <Link to="/organisation" target="_blank">
                          Add a new Company
                        </Link>
                      </Label>
                      <Input
                        type="select"
                        value={selectedOrg}
                        onChange={e => setSelectedOrg(e.target.value)}
                      >
                        <option value="">Select Organisation</option>
                        {organisations.map(org => (
                          <option key={org.key} value={org.key}>
                            {org.businessName}
                          </option>
                        ))}
                      </Input>
                    </div>
                    {renderWorkerSelect()}{" "}
                    {/* Render worker select if user type is Worker */}
                    {/* Submit button */}
                    <div className="mt-4">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Register
                      </button>

                    </div>
                    {registrationError && (
                      <Alert color="danger">{registrationError}</Alert>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
   )
}

export default AdminRegister
