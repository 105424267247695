import React, { useState, useEffect } from "react"
import {
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import NewsService from "../../services/NewsService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const NewsManager = () => {
  const [news, setNews] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentNews, setCurrentNews] = useState(null)
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    excerpt: "",
    image: "",
  })

  const fetchNews = async () => {
    try {
      const data = await NewsService.getAllNews()
      setNews(data)
    } catch (error) {
      console.error("Error fetching news:", error)
    }
  }

  useEffect(() => {
    fetchNews()
  }, [])

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleAdd = () => {
    setCurrentNews(null)
    setFormData({ title: "", date: "", excerpt: "", image: "" })
    setModalOpen(true)
  }

  const handleEdit = newsItem => {
    setCurrentNews(newsItem)
    setFormData({
      title: newsItem.title,
      date: newsItem.date,
      excerpt: newsItem.excerpt,
      image: newsItem.image,
    })
    setModalOpen(true)
  }

  const handleSave = async () => {
    try {
      if (currentNews) {
        await NewsService.updateNews(currentNews.key, formData)
        alert("News updated successfully!")
      } else {
        const newNews = { ...formData, createdAt: Date.now() }
        await NewsService.createNews(newNews)
        alert("New news added successfully!")
      }
      fetchNews()
      toggleModal()
    } catch (error) {
      console.error("Error saving news:", error)
      alert("An error occurred while saving the news.")
    }
  }

  const handleDelete = async newsKey => {
    if (window.confirm("Are you sure you want to delete this news?")) {
      try {
        await NewsService.deleteNews(newsKey)
        alert("News deleted successfully!")
        fetchNews()
      } catch (error) {
        console.error("Error deleting news:", error)
        alert("An error occurred while deleting the news.")
      }
    }
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <h1>News Manager</h1>
        <Button color="success" className="mb-3" onClick={handleAdd}>
          Add New News
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Title</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {news.map(item => (
              <tr key={item.key}>
                <td>{item.title}</td>
                <td>{item.date}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(item.key)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentNews ? "Edit News" : "Add New News"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                id="title"
                name="title"
                value={formData.title}
                onChange={e =>
                  setFormData({ ...formData, title: e.target.value })
                }
                placeholder="Enter news title"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="date">Date</Label>
              <Input
                id="date"
                name="date"
                type="date"
                value={formData.date}
                onChange={e =>
                  setFormData({ ...formData, date: e.target.value })
                }
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="excerpt">Excerpt</Label>
              <Input
                id="excerpt"
                name="excerpt"
                type="textarea"
                value={formData.excerpt}
                onChange={e =>
                  setFormData({ ...formData, excerpt: e.target.value })
                }
                placeholder="Enter news excerpt"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="image">Image URL</Label>
              <Input
                id="image"
                name="image"
                value={formData.image}
                onChange={e =>
                  setFormData({ ...formData, image: e.target.value })
                }
                placeholder="Enter news image URL"
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Footer />
    </>
  )
}

export default NewsManager
