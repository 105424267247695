import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import JobDataService from "./../../services/JobDataService.js"
import { Link } from "react-router-dom"
import {
  PieChart,
  Series,
  Label,
  Connector,
  Legend,
} from "devextreme-react/pie-chart"
import { BarGauge } from "devextreme-react/bar-gauge"

const TopJobsChart = () => {
  const [topCitiesJobData, setTopCitiesJobData] = useState([])

  useEffect(() => {
    JobDataService.getAll(jobs => {
      const locationCounts = jobs.reduce((acc, job) => {
        const location = job.location
        acc[location] = (acc[location] || 0) + 1
        return acc
      }, {})

      const locationsArray = Object.entries(locationCounts)
      const totalJobs = locationsArray.reduce(
        (sum, [, count]) => sum + count,
        0
      )

      const sortedData = locationsArray
        .sort((a, b) => b[1] - a[1])
        .map(([location, count], index) => ({
          location,
          value: count,
          percentage: (count / totalJobs) * 100,
        }))

      // Take only the top 10 locations
      const topTenData = sortedData.slice(0, 10)
      setTopCitiesJobData(topTenData)
    })
  }, [])

  const customizeText = arg => {
    return `${arg.argumentText}: ${arg.valueText}`
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Jobs By Location</CardTitle>

          <div className="mb-4">
            <PieChart
              id="topJobsPie"
              dataSource={topCitiesJobData}
              title="Job Distribution by Location"
            >
              <Series argumentField="location" valueField="value">
                <Label visible={true} customizeText={customizeText}>
                  <Connector visible={true} />
                </Label>
              </Series>
              <Legend
                orientation="horizontal"
                itemTextPosition="right"
                horizontalAlignment="center"
                verticalAlignment="bottom"
              />
            </PieChart>
          </div>

          <div className="mb-4">
            <BarGauge
              id="topJobsBarGauge"
              startValue={0}
              endValue={100}
              values={topCitiesJobData.map(item => item.percentage)}
              label={{ visible: true, format: "percent" }}
            />
          </div>

          <div className="text-center mt-4">
            <Link to="/jobs" className="btn btn-primary">
              View More <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopJobsChart
