import React, { useState, useEffect } from "react"
import {
  Scheduler,
  Resource,
  View,
  Editing,
  AppointmentDragging,
  Scrolling,
} from "devextreme-react/scheduler"
import { Popup } from "devextreme-react/popup"
import {
  Form,
  SimpleItem,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form"
import { DataGrid, Column, Pager, Paging } from "devextreme-react/data-grid"
import "devextreme/dist/css/dx.light.css"
import JobDataService from "services/JobDataService"
import AllocationModal from "./AllocationModal.js"
import TimeModal from "./TimeModal.js"
import CancelJobModal from "./CancelJobModal.js"
import WorkersAllocationService from "services/WorkersAllocationService" // Adjust the path as needed

const Allocate = () => {
  const [jobs, setJobs] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isAppointmentFormOpen, setIsAppointmentFormOpen] = useState(false)
  const [currentAppointmentData, setCurrentAppointmentData] = useState(null)
  const [isAllocationModalOpen, setIsAllocationModalOpen] = useState(false)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [isCancelJobModalOpen, setIsCancelJobModalOpen] = useState(false)

  const openAppointmentForm = appointmentData => {
    setCurrentAppointmentData(appointmentData)
    setIsAppointmentFormOpen(true)
  }

  const closeAppointmentForm = () => {
    setIsAppointmentFormOpen(false)
    setCurrentAppointmentData(null)
  }

  const openAllocationModal = jobData => {
    setCurrentAppointmentData(jobData)
    setIsAllocationModalOpen(true)
  }

  const closeAllocationModal = () => {
    setIsAllocationModalOpen(false)
  }

  const openTimeModal = jobData => {
    setCurrentAppointmentData(jobData)
    setIsTimeModalOpen(true)
  }

  const closeTimeModal = () => {
    setIsTimeModalOpen(false)
  }

  const openCancelJobModal = jobData => {
    setCurrentAppointmentData(jobData)
    setIsCancelJobModalOpen(true)
  }

  const closeCancelJobModal = () => {
    setIsCancelJobModalOpen(false)
  }

  useEffect(() => {
    const unsubscribe = JobDataService.getAll(data => {
      setJobs(data)
    })
    return () => unsubscribe && unsubscribe()
  }, [])

  const formatJobsForScheduler = jobsData => {
    return jobsData.map(job => {
      const startDate = new Date(`${job.startDate}T${job.startTime}:00`)
      const endDate = new Date(`${job.endDate}T${job.endTime}:00`)

      let resource
      switch (job.jobstatus) {
        case "open":
          resource = "open"
          break
        case "open over-due":
          resource = "openOverdue"
          break
        case "allocated":
          resource = "allocated"
          break
        case "completed":
          resource = "completed"
          break
        case "cancelled":
          resource = "cancelled"
          break
        default:
          resource = "default"
      }

      return {
        id: job.key,
        provider: job.provider,
        jobType: job.jobType,
        text: job.jobTitle || "", // Ensure jobTitle is used for display
        jobTitle: job.jobTitle || "", // Ensure jobTitle is stored for appointment template
        startDate: startDate,
        endDate: endDate,
        resourceId: resource,
        jobstatus: job.jobstatus,
      }
    })
  }

  const schedulerData = formatJobsForScheduler(jobs)

  const saveJobToService = async (id, data) => {
    const jobData = {
      ...data,
      startDate: data.startDate.toISOString().split("T")[0],
      startTime: data.startDate.toTimeString().split(" ")[0].slice(0, 5),
      endDate: data.endDate.toISOString().split("T")[0],
      endTime: data.endDate.toTimeString().split(" ")[0].slice(0, 5),
    }

    if (id) {
      await JobDataService.updateJob(id, jobData)
    } else {
      await JobDataService.createJob(jobData)
    }
  }

  const onAppointmentFormSubmit = async e => {
    const { formData } = e
    await saveJobToService(currentAppointmentData?.id, formData)
    closeAppointmentForm()
  }

  const onCancelJobSubmit = async jobData => {
    await saveJobToService(jobData.id, {
      ...jobData,
      jobstatus: "cancelled",
    })
    setIsCancelJobModalOpen(false)
    setIsAppointmentFormOpen(false)
  }

  const onAppointmentFormOpening = e => {
    const { form, appointmentData } = e

    const formItems = [
      {
        label: { text: "Job Title" },
        dataField: "jobTitle",
        editorType: "dxTextBox",
        editorOptions: {
          value: appointmentData.jobTitle || "",
        },
      },
      {
        label: { text: "Provider" },
        dataField: "provider",
        editorType: "dxTextBox",
        editorOptions: {
          value: appointmentData.provider || "",
        },
      },
      {
        label: { text: "Start Date" },
        dataField: "startDate",
        editorType: "dxDateBox",
        editorOptions: {
          type: "datetime",
          displayFormat: "dd/MM/yyyy HH:mm",
          value: appointmentData.startDate || new Date(),
        },
      },
      {
        label: { text: "End Date" },
        dataField: "endDate",
        editorType: "dxDateBox",
        editorOptions: {
          type: "datetime",
          displayFormat: "dd/MM/yyyy HH:mm",
          value: appointmentData.endDate || new Date(),
        },
      },
      {
        label: { text: "Status" },
        dataField: "jobstatus",
        editorType: "dxSelectBox",
        editorOptions: {
          items: [
            { id: "open", text: "Open" },
            { id: "open over-due", text: "Open Over-due" },
            { id: "allocated", text: "Allocated" },
            { id: "completed", text: "Completed" },
            { id: "cancelled", text: "Cancelled" },
          ],
          displayExpr: "text",
          valueExpr: "id",
          value: appointmentData.jobstatus || "open",
        },
      },
      {
        label: { text: "Job Type" },
        dataField: "jobType",
        editorType: "dxSelectBox",
        editorOptions: {
          items: [
            { id: "Support worker", text: "Support worker" },
            {
              id: "Residential Support worker(sleep in)",
              text: "Residential Support worker(sleep in)",
            },
            {
              id: "Registered General Nurse",
              text: "Registered General Nurse",
            },
            { id: "Doctor", text: "Doctor" },
          ],
          displayExpr: "text",
          valueExpr: "id",
          value: appointmentData.jobType || "Support worker",
        },
      },
      {
        itemType: "button",
        horizontalAlignment: "left",
        buttonOptions: {
          text: "Allocation",
          onClick: () => openAllocationModal(appointmentData),
          elementAttr: {
            style: {
              backgroundColor: "#8BC34A", // Green
              color: "white",
            },
          },
        },
      },
      {
        itemType: "button",
        horizontalAlignment: "left",
        buttonOptions: {
          text: "Cancel Job",
          onClick: () => openCancelJobModal(appointmentData),
          elementAttr: {
            style: {
              backgroundColor: "#F44336", // Red
              color: "white",
            },
          },
        },
      },
    ]

    // Add the Time button only if the job status is completed
    if (appointmentData.jobstatus === "completed") {
      formItems.push({
        itemType: "button",
        horizontalAlignment: "left",
        buttonOptions: {
          text: "Time",
          onClick: () => openTimeModal(appointmentData),
          elementAttr: {
            style: {
              backgroundColor: "#FFC107", // Amber
              color: "white",
            },
          },
        },
      })
    }

    form.option("items", formItems)
  }

  const renderAppointmentContent = model => {
    const { targetedAppointmentData } = model.data

    if (!targetedAppointmentData || !targetedAppointmentData.resourceId) {
      return <div style={{ height: "100%", padding: "5px" }}>No job</div>
    }

    let color

    switch (targetedAppointmentData.resourceId) {
      case "open":
        color = "#8BC34A" // Green
        break
      case "openOverdue":
        color = "#FF9800" // Orange
        break
      case "allocated":
        color = "#FFC107" // Amber
        break
      case "completed":
        color = "#4CAF50" // Dark Green
        break
      case "cancelled":
        color = "#F44336" // Red
        break
      default:
        color = "#E0E0E0" // Grey
    }

    return (
      <div style={{ backgroundColor: color, height: "100%", padding: "5px" }}>
        <b>{targetedAppointmentData.jobTitle || "No title"}</b>
        <br />
        <span>
          {new Date(targetedAppointmentData.startDate).toLocaleString("en-GB")}{" "}
          - {new Date(targetedAppointmentData.endDate).toLocaleString("en-GB")}
        </span>
      </div>
    )
  }

  return (
    <div className="page-content">
      <Scheduler
        dataSource={schedulerData}
        defaultCurrentView="week"
        startDayHour={7}
        endDayHour={19}
        showAllDayPanel={false}
        height={600}
        editing={{
          allowAdding: true,
          allowDeleting: true,
          allowUpdating: true,
        }}
        appointmentComponent={renderAppointmentContent}
        onAppointmentAdding={async e => {
          const formData = {
            jobTitle: e.appointmentData.jobTitle,
            provider: e.appointmentData.provider,
            startDate: new Date(e.appointmentData.startDate),
            endDate: new Date(e.appointmentData.endDate),
            jobstatus: e.appointmentData.jobstatus,
            resourceId: e.appointmentData.jobstatus.replace(" ", ""),
          }

          await saveJobToService(null, formData)
        }}
        onAppointmentUpdating={async e => {
          console.log("Appointment Updating Event:", e)
          if (!e.newData || !e.appointmentData) {
            console.error("Missing data in the updating event", e)
            return
          }

          const formData = {
            jobTitle: e.newData.jobTitle,
            provider: e.newData.provider || e.oldData.provider,
            startDate: new Date(e.newData.startDate),
            endDate: new Date(e.newData.endDate),
            jobstatus: e.newData.jobstatus,
            resourceId: e.newData.jobstatus.replace(" ", ""),
          }

          await saveJobToService(e.oldData.id, formData)
        }}
        onAppointmentDeleting={async e => {
          await JobDataService.deleteJob(e.appointmentData.id)
        }}
        onAppointmentFormOpening={onAppointmentFormOpening}
      >
        <View type="day" />
        <View type="week" />
        <View type="month" />
        <Resource
          dataSource={[
            { id: "open", text: "Open", color: "#8BC34A" },
            { id: "openOverdue", text: "Open Over-due", color: "#FF9800" },
            { id: "allocated", text: "Allocated", color: "#FFC107" },
            { id: "completed", text: "Completed", color: "#4CAF50" },
            { id: "cancelled", text: "Cancelled", color: "#F44336" },
          ]}
          fieldExpr="resourceId"
          label="Job Status"
          useColorAsDefault={true}
        />
        <Editing allowResizing allowDragging allowUpdating />
        <AppointmentDragging />
        <Scrolling mode="virtual" />
      </Scheduler>

      <DataGrid
        dataSource={schedulerData}
        keyExpr="id"
        showBorders={true}
        onRowClick={e => openAppointmentForm(e.data)}
      >
        <Column dataField="id" caption="ID" width={50} />
        <Column dataField="jobTitle" caption="Job Details" />
        <Column
          dataField="startDate"
          caption="Start Date"
          dataType="datetime"
        />
        <Column dataField="endDate" caption="End Date" dataType="datetime" />
        <Pager visible={true} />
        <Paging pageSize={10} />
      </DataGrid>

      <Popup
        visible={isAppointmentFormOpen}
        onHiding={closeAppointmentForm}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Job Details"
        width={300}
        height={250}
        position={{ my: "center", at: "center", of: window }}
      >
        <Form
          formData={currentAppointmentData}
          onEditorEnterKey={onAppointmentFormSubmit}
        >
          <SimpleItem dataField="jobTitle" isRequired={true}>
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField="provider" isRequired={true}>
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField="startDate"
            editorType="dxDateBox"
            editorOptions={{
              type: "datetime",
              displayFormat: "dd/MM/yyyy HH:mm", // British date format
            }}
            isRequired={true}
          >
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField="endDate"
            editorType="dxDateBox"
            editorOptions={{
              type: "datetime",
              displayFormat: "dd/MM/yyyy HH:mm", // British date format
            }}
            isRequired={true}
          >
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField="jobstatus"
            editorType="dxSelectBox"
            isRequired={true}
          >
            <RequiredRule />
          </SimpleItem>
          <ButtonItem
            horizontalAlignment="left"
            buttonOptions={{
              text: "Allocation",
              onClick: () => openAllocationModal(currentAppointmentData),
              elementAttr: {
                style: {
                  backgroundColor: "#8BC34A", // Green
                  color: "white",
                },
              },
            }}
          />
          {currentAppointmentData?.jobstatus === "completed" && (
            <ButtonItem
              horizontalAlignment="left"
              buttonOptions={{
                text: "Time",
                onClick: () => openTimeModal(currentAppointmentData),
                elementAttr: {
                  style: {
                    backgroundColor: "#FFC107", // Amber
                    color: "white",
                  },
                },
              }}
            />
          )}
          <ButtonItem
            horizontalAlignment="left"
            buttonOptions={{
              text: "Cancel Job",
              onClick: () => openCancelJobModal(currentAppointmentData),
              elementAttr: {
                style: {
                  backgroundColor: "#F44336", // Red
                  color: "white",
                },
              },
            }}
          />
        </Form>
      </Popup>

      <AllocationModal
        visible={isAllocationModalOpen}
        onHiding={closeAllocationModal}
        jobData={currentAppointmentData}
      />

      <TimeModal
        visible={isTimeModalOpen}
        onHiding={closeTimeModal}
        jobData={currentAppointmentData}
      />

      <CancelJobModal
        visible={isCancelJobModalOpen}
        onHiding={closeCancelJobModal}
        jobData={currentAppointmentData}
        onSubmit={onCancelJobSubmit}
      />
    </div>
  )
}

export default Allocate
