import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
} from "firebase/database"

class CareerCenterService {
  constructor() {
    // Reference to the 'jobListings' path in Firebase Realtime Database
    this.dbRef = ref(getDatabase(), "/jobListings")
  }

  // Fetch all job listings
  getAllJobListings() {
    return new Promise((resolve, reject) => {
      // Query to order job listings by 'createdAt' field
      const jobsByLatestRef = query(this.dbRef, orderByChild("createdAt"))

      onValue(
        jobsByLatestRef,
        snapshot => {
          const data = snapshot.val()
          let jobListings = []

          if (data) {
            // Convert object to an array of job listings and include the 'key' property
            jobListings = Object.keys(data).map(key => ({ key, ...data[key] }))

            // Sort job listings by 'createdAt' in descending order (latest first)
            jobListings.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)) // Descending order
          }

          resolve(jobListings) // Resolve with sorted job listings
        },
        error => {
          reject(error) // Reject promise if there is an error
        }
      )
    })
  }

  // Create a new job listing
  createJobListing(jobListing) {
    return push(this.dbRef, jobListing)
  }

  // Update an existing job listing by key
  updateJobListing(key, value) {
    const specificJobRef = ref(getDatabase(), `/jobListings/${key}`)
    return update(specificJobRef, value)
  }

  // Delete a job listing by key
  deleteJobListing(key) {
    const specificJobRef = ref(getDatabase(), `/jobListings/${key}`)
    return remove(specificJobRef)
  }
}

export default new CareerCenterService()
