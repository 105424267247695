import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { getApps, getApp } from "firebase/app"
import { getFirestore, collection, addDoc } from "firebase/firestore"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import "./ContactUs.css"
import ContactDataService from "../../services/ContactDataService.js"; // Adjust path as needed

// Initialize Firestore using the default Firebase app
const db = getFirestore(getApps().length ? getApp() : null)

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

const handleSubmit = async e => {
  e.preventDefault()

  try {
    const newContact = {
      ...formData,
      createdAt: Date.now(), // Add timestamp for sorting
    }

    await ContactDataService.createContact(newContact) // Use ContactDataService to save the contact
    alert("Your message has been sent! We will get back to you soon.")
    setFormData({ name: "", email: "", phone: "", message: "" }) // Reset the form
  } catch (error) {
    console.error("Error creating contact: ", error)
    alert("An error occurred while sending your message. Please try again.")
  }
}
  return (
    <>
      <Header />

      <div className="page-content">
        {/* Hero Section */}
        <section className="hero-section text-white text-center py-5">
          <Container>
            <h1 className="display-4">Contact Us</h1>
            <p className="lead">
              We’d love to hear from you. Get in touch with us for any inquiries
              or support.
            </p>
          </Container>
        </section>

        {/* Contact Form Section */}
        <section className="contact-form-section py-5">
          <Container>
            <Row>
              <Col md="6">
                <h2>Get in Touch</h2>
                <p>
                  Fill out the form below, and one of our team members will
                  contact you shortly.
                </p>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">Phone</Label>
                    <Input
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <Input
                      id="message"
                      name="message"
                      type="textarea"
                      rows="4"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Write your message here"
                      required
                    />
                  </FormGroup>
                  <Button color="primary" type="submit">
                    Send Message
                  </Button>
                </Form>
              </Col>

              {/* Contact Information */}
              <Col md="6">
                <h2>Contact Information</h2>
                <p>
                  If you prefer, you can reach us directly through the following
                  contact methods:
                </p>
                <ul className="list-unstyled">
                  <li>
                    <strong>Phone:</strong> 01253834269
                  </li>
                  <li>
                    <strong>Email:</strong> support@care-tip.com
                  </li>
                  <li>
                    <strong>Address:</strong> 291-305 Lytham Road FY4 1EW
                  </li>
                  <li>
                    <strong>Business Hours:</strong> Monday - Friday, 9:00 AM -
                    5:00 PM
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Map Section */}
        <section className="map-section py-5 bg-light">
          <Container>
            <h2 className="text-center mb-4">Our Location</h2>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.8354345093206!2d144.95373531567844!3d-37.817209442021984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf57719b0ac30d53!2s123%20Care-Tip%20Street%2C%20Leicester%20LE1%202AB!5e0!3m2!1sen!2suk!4v1636967703215!5m2!1sen!2suk"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default ContactUs
