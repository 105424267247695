import React, { useEffect, useState } from "react"
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import JobApplicationDataService from "../../services/JobApplicationDataService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const CareerManager = () => {
  const [applications, setApplications] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentApplication, setCurrentApplication] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    jobTitle: "",
    resumeURL: "",
  })

  // Fetch all job applications
  const fetchApplications = async () => {
    try {
      const apps = await JobApplicationDataService.getAllApplications()
      setApplications(apps)
    } catch (error) {
      console.error("Error fetching applications:", error)
    }
  }

  useEffect(() => {
    fetchApplications()
  }, [])

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Open modal for editing
  const handleEdit = application => {
    setCurrentApplication(application)
    setFormData(application)
    setModalOpen(true)
  }

  // Close modal
  const toggleModal = () => {
    setModalOpen(!modalOpen)
    setCurrentApplication(null)
    setFormData({
      name: "",
      email: "",
      phone: "",
      jobTitle: "",
      resumeURL: "",
    })
  }

  // Update application
  const handleUpdate = async () => {
    try {
      await JobApplicationDataService.updateApplication(
        currentApplication.id,
        formData
      )
      alert("Application updated successfully!")
      toggleModal()
      fetchApplications() // Refresh applications
    } catch (error) {
      console.error("Error updating application:", error)
      alert("An error occurred while updating the application.")
    }
  }

  // Delete application
  const handleDelete = async id => {
    try {
      await JobApplicationDataService.deleteApplication(id)
      alert("Application deleted successfully!")
      fetchApplications() // Refresh applications
    } catch (error) {
      console.error("Error deleting application:", error)
      alert("An error occurred while deleting the application.")
    }
  }

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1>Career Manager</h1>
        <Table striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Job Title</th>
              <th>Resume</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {applications.map(app => (
              <tr key={app.id}>
                <td>{app.name}</td>
                <td>{app.email}</td>
                <td>{app.phone}</td>
                <td>{app.jobTitle}</td>
                <td>
                  <a
                    href={app.resumeURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Resume
                  </a>
                </td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEdit(app)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(app.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal for Editing Application */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Application</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobTitle">Job Title</Label>
              <Input
                id="jobTitle"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="resumeURL">Resume URL</Label>
              <Input
                id="resumeURL"
                name="resumeURL"
                value={formData.resumeURL}
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Footer />
    </>
  )
}

export default CareerManager
