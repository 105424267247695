import React, { useState, useEffect } from "react"
import {
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import GuideService from "../../services/GuideService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"

const GuideManager = () => {
  const [guides, setGuides] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentGuide, setCurrentGuide] = useState(null)
  const [formData, setFormData] = useState({ title: "", description: "" })

  const fetchGuides = async () => {
    try {
      const data = await GuideService.getAllGuides()
      setGuides(data)
    } catch (error) {
      console.error("Error fetching guides:", error)
    }
  }

  useEffect(() => {
    fetchGuides()
  }, [])

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleAdd = () => {
    setCurrentGuide(null)
    setFormData({ title: "", description: "" })
    setModalOpen(true)
  }

  const handleEdit = guide => {
    setCurrentGuide(guide)
    setFormData({ title: guide.title, description: guide.description })
    setModalOpen(true)
  }

  const handleSave = async () => {
    try {
      if (currentGuide) {
        await GuideService.updateGuide(currentGuide.key, formData)
        alert("Guide updated successfully!")
      } else {
        const newGuide = { ...formData, createdAt: Date.now() }
        await GuideService.createGuide(newGuide)
        alert("New guide added successfully!")
      }
      fetchGuides()
      toggleModal()
    } catch (error) {
      console.error("Error saving guide:", error)
      alert("An error occurred while saving the guide.")
    }
  }

  const handleDelete = async guideKey => {
    if (window.confirm("Are you sure you want to delete this guide?")) {
      try {
        await GuideService.deleteGuide(guideKey)
        alert("Guide deleted successfully!")
        fetchGuides()
      } catch (error) {
        console.error("Error deleting guide:", error)
        alert("An error occurred while deleting the guide.")
      }
    }
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <h1>Guide Manager</h1>
        <Button color="success" className="mb-3" onClick={handleAdd}>
          Add New Guide
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {guides.map(guide => (
              <tr key={guide.key}>
                <td>{guide.title}</td>
                <td>{guide.description}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEdit(guide)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDelete(guide.key)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentGuide ? "Edit Guide" : "Add New Guide"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                id="title"
                name="title"
                value={formData.title}
                onChange={e =>
                  setFormData({ ...formData, title: e.target.value })
                }
                placeholder="Enter guide title"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                name="description"
                type="textarea"
                value={formData.description}
                onChange={e =>
                  setFormData({ ...formData, description: e.target.value })
                }
                placeholder="Enter guide description"
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Footer />
    </>
  )
}

export default GuideManager
