import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
} from "firebase/database"

class SubscriptionService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/subscriptions") // Reference to "subscriptions" path in Firebase
  }

  // Create a new subscription
  createSubscription(subscription) {
    return push(this.dbRef, subscription)
  }

  // Get all subscriptions
  getAllSubscriptions() {
    return new Promise((resolve, reject) => {
      onValue(
        this.dbRef,
        snapshot => {
          const data = snapshot.val()
          const subscriptions = data
            ? Object.keys(data).map(key => ({ id: key, ...data[key] }))
            : []
          resolve(subscriptions)
        },
        error => reject(error)
      )
    })
  }

  // Update a subscription
  updateSubscription(subscriptionId, updatedData) {
    const subscriptionRef = ref(
      getDatabase(),
      `/subscriptions/${subscriptionId}`
    )
    return update(subscriptionRef, updatedData)
  }

  // Delete a subscription
  deleteSubscription(subscriptionId) {
    const subscriptionRef = ref(
      getDatabase(),
      `/subscriptions/${subscriptionId}`
    )
    return remove(subscriptionRef)
  }
}

export default new SubscriptionService()
