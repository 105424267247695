import React, { useState, useEffect } from "react"
import {
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import CareerCenterService from "../../services/CareerCenterService.js"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"



const CareerCenterManager = () => {
  const [jobListings, setJobListings] = useState([]) // Job listings data
  const [modalOpen, setModalOpen] = useState(false) // Modal state
  const [currentJob, setCurrentJob] = useState(null) // Currently selected job
  const [formData, setFormData] = useState({
    title: "",
    location: "",
    type: "",
    description: "",
  }) // Form data

  // Fetch all job listings from the database
  const fetchJobListings = async () => {
    try {
      const jobs = await CareerCenterService.getAllJobListings()
      setJobListings(jobs)
    } catch (error) {
      console.error("Error fetching job listings:", error)
      alert("Failed to load job listings. Please try again.")
    }
  }

  useEffect(async () => {
    fetchJobListings()
  }, [])

  // Handle opening the modal for adding or editing jobs
  const handleAdd = () => {
    setCurrentJob(null)
    setFormData({ title: "", location: "", type: "", description: "" })
    setModalOpen(true)
  }

  const handleEdit = job => {
    setCurrentJob(job)
    setFormData(job)
    setModalOpen(true)
  }

  // Toggle modal visibility
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  // Handle form field changes
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Save job listing (add or update)
  const handleSave = async () => {
    if (
      !formData.title ||
      !formData.location ||
      !formData.type ||
      !formData.description
    ) {
      alert("All fields are required.")
      return
    }

    try {
      if (currentJob) {
        // Update existing job
        await CareerCenterService.updateJobListing(currentJob.key, formData)
        alert("Job listing updated successfully!")
      } else {
        // Add new job
        const newJob = { ...formData, createdAt: Date.now() }
        await CareerCenterService.createJobListing(newJob)
        alert("New job listing added successfully!")
      }
      fetchJobListings()
      toggleModal()
    } catch (error) {
      console.error("Error saving job listing:", error)
      alert("Failed to save job listing. Please try again.")
    }
  }

  // Delete a job listing
  const handleDelete = async jobId => {
    if (!window.confirm("Are you sure you want to delete this job listing?")) {
      return
    }

    try {
      await CareerCenterService.deleteJobListing(jobId)
      alert("Job listing deleted successfully!")
      fetchJobListings()
    } catch (error) {
      console.error("Error deleting job listing:", error)
      alert("Failed to delete job listing. Please try again.")
    }
  }

  return (
    <>
      <Header />
      <Container className="my-5">
        <h1>Career Center Manager</h1>
        <Button color="success" className="mb-3" onClick={handleAdd}>
          Add New Job
        </Button>
        <Table striped>
          <thead>
            <tr>
              <th>Title</th>
              <th>Location</th>
              <th>Type</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobListings.length > 0 ? (
              jobListings.map(job => (
                <tr key={job.key}>
                  <td>{job.title}</td>
                  <td>{job.location}</td>
                  <td>{job.type}</td>
                  <td>{job.description}</td>
                  <td>
                    <Button
                      color="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEdit(job)}
                    >
                      Edit
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDelete(job.key)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No job listings found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>

      {/* Modal for Adding/Editing Job Listings */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentJob ? "Edit Job Listing" : "Add New Job Listing"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Enter job title"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">Location</Label>
              <Input
                id="location"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                placeholder="Enter job location"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input
                id="type"
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                placeholder="Enter job type (e.g., Full-Time, Part-Time)"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                name="description"
                type="textarea"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter job description"
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Footer />
    </>
  )
}

export default CareerCenterManager
